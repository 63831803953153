import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import {
    borderColorValue,
    borderWidthValue,
    getCSSVariable,
} from '../../constants/cssRules'

const StyledTableItem = styled.article.withConfig(DEFAULT_CONFIG)(props => {
    const paddingBottom = getCSSVariable('spacingMedium')
    return {
        width: '100%',
        paddingBottom,
        borderBottom: `${borderWidthValue(props)} ${
            props.noLine ? 'transparent' : borderColorValue(props)
        } solid`,
        marginBottom: props.noLine ? 'unset' : paddingBottom,
    }
})

const TableItem = ({ className, id, children, noLine, style }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledTableItem
            className={className}
            style={style}
            id={id}
            themeContext={themeContext}
            noLine={noLine}
        >
            {children}
        </StyledTableItem>
    )
}

export default TableItem
