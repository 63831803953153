import React from 'react'
import styled from 'styled-components'
import { ThemeVariants } from '../constants'
import { getCSSVariable } from '../constants/cssRules'
import Icon from './Icon'
import Card from './Card'
import ComponentError from './States/ComponentError'
import { DEFAULT_CONFIG } from '../constants/styledComponents'

const StyledCarrouselContainer = styled.div.withConfig(DEFAULT_CONFIG)(
    props => ({
        transform: props.isMobile
            ? undefined
            : props.currentStep > 0
              ? `translateX(calc((${props.itemWidth} + ${getCSSVariable(
                    'spacingSmall',
                )}) * -${props.currentStep}))`
              : undefined,
    }),
)

const StyledCarrouselItem = styled.div.withConfig(DEFAULT_CONFIG)(props => ({
    width: props.isMobile ? '90%' : props.itemWidth,
    maxWidth: props.isMobile ? '90%' : props.itemWidth,
    minWidth: props.isMobile ? '90%' : props.itemWidth,
    marginRight: props.isMobile ? getCSSVariable('spacingSmall') : undefined,
    whiteSpace: 'break-spaces',
    '>:first-child': {
        display: 'inline-grid',
        gridAutoRows: 'max-content',
        height: '100%',
        width: '100%',
    },
}))

const Carrousel = ({
    id,
    className,
    children,
    itemsPerPage = 5,
    steps = 1,
    firstBreakpoint = 800,
    withOverflow = false,
}) => {
    const [currentStep, setCurrentStep] = React.useState(0)
    const [containerWidth, setContainerWidth] = React.useState(0)
    const itemWidth = `calc(${100 / itemsPerPage}% - ${getCSSVariable(
        'spacingSmall',
    )})`
    const me = React.useRef()
    const isMobile = containerWidth < firstBreakpoint

    const onHandlerClick = isRight => {
        const itemsCount = children.length
        let virtualItemsCount = currentStep + itemsPerPage
        if (steps === 1) {
            virtualItemsCount++
        }
        let newStep
        if (isRight && virtualItemsCount > itemsCount) {
            newStep = 0
        } else {
            newStep = isRight ? currentStep + steps : currentStep - steps
        }
        setCurrentStep(newStep)
    }

    React.useEffect(() => {
        const handleWindowSize = () => {
            setContainerWidth(me.current.offsetWidth)
        }
        window.addEventListener('resize', handleWindowSize)
        return () => {
            window.removeEventListener('resize', handleWindowSize)
        }
    }, [])

    React.useEffect(() => {
        if (me.current?.offsetWidth) {
            setContainerWidth(me.current.offsetWidth)
        }
    }, [])

    if (!children) {
        return (
            <ComponentError
                componentName='Carrousel'
                errorMessage='A "children" property must be provided'
            />
        )
    }
    return (
        <div
            ref={me}
            id={id}
            className={`rds-carrousel ${className || ''} ${
                isMobile ? 'rds-overflow_auto' : ''
            }`}
        >
            {isMobile ? null : (
                <div className='rds-carrousel-handlers'>
                    <Card
                        className={`rds-carrousel-handler rds-carrousel-handler_left ${
                            currentStep === 0
                                ? 'rds-carrousel-handler_disabled'
                                : ''
                        }`}
                        variant={ThemeVariants.DARK}
                        onClick={() => onHandlerClick()}
                    >
                        <Icon
                            type='Left'
                            variant={ThemeVariants.WHITE}
                            size={20}
                            className='rds-m_around__sm'
                        />
                    </Card>
                    <Card
                        className='rds-carrousel-handler rds-carrousel-handler_right'
                        variant={ThemeVariants.DARK}
                        onClick={() => onHandlerClick(true)}
                    >
                        <Icon
                            type='Right'
                            variant={ThemeVariants.WHITE}
                            size={20}
                            className='rds-m_around__sm'
                        />
                    </Card>
                </div>
            )}
            <div
                className={`rds-carrousel-container_wrapper ${
                    withOverflow
                        ? ''
                        : isMobile
                          ? 'rds-overflow_auto'
                          : 'rds-overflow_hidden'
                }`}
            >
                <StyledCarrouselContainer
                    className='rds-carrousel-container rds-flex rds-nowrap'
                    currentStep={currentStep}
                    itemWidth={itemWidth}
                    isMobile={isMobile}
                >
                    {children.map((child, index) => (
                        <StyledCarrouselItem
                            key={index}
                            className='rds-carrousel-item rds-m_right__sm'
                            itemWidth={itemWidth}
                            isMobile={isMobile}
                        >
                            {child}
                        </StyledCarrouselItem>
                    ))}
                </StyledCarrouselContainer>
            </div>
        </div>
    )
}

export default Carrousel
