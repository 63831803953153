import React from 'react'
import Template from '../layout/Template'
import { Label, Block, ThemeVariants } from 'rds'

const LabelTemplate = () => {
    return (
        <Template
            hashValue='label'
            componentName='Label'
            overview={
                <div>
                    <div>
                        <Label>This is a label</Label>
                    </div>
                    <div className='rds-m_top__sm'>
                        <Label variant={ThemeVariants.MAIN}>
                            Main variant label
                        </Label>
                    </div>
                    <div className='rds-m_top__sm'>
                        <Label variant={ThemeVariants.SECONDARY}>
                            Secondary variant label
                        </Label>
                    </div>
                    <div className='rds-m_top__sm'>
                        <Label variant={ThemeVariants.DARK}>
                            Dark variant label
                        </Label>
                    </div>
                    <div className='rds-m_top__sm'>
                        <Block
                            variant={ThemeVariants.DARK}
                            style={{ display: 'inline-block' }}
                            className='rds-p_around__sm'
                        >
                            <Label variant={ThemeVariants.WHITE}>
                                White variant label
                            </Label>
                        </Block>
                    </div>
                </div>
            }
            example={`
<Label
    variant={ThemeVariants.<NAME>}
>
    // Label content...
</Label>`}
            properties={[
                {
                    name: 'variant',
                    description:
                        'By the momento, only ThemeVariants.WHITE is supported',
                },
            ]}
        />
    )
}

export default LabelTemplate
