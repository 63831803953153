import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs } from 'rds'
import Template from '../layout/Template'

const VALUES = Object.freeze([
    { value: 'homepage', label: 'Home' },
    { value: 'categories', label: 'Categories' },
    { value: 'tech', label: 'Technology' },
])

const BreadcrumbsTemplate = () => {
    const navigate = useNavigate()
    const [value, setValue] = React.useState('tech')

    return (
        <Template
            hashValue='breadcrumbs'
            componentName='Breadcrumbs'
            overview={
                <Breadcrumbs
                    value={value}
                    values={VALUES}
                    onClick={value => {
                        setValue(value)
                        navigate(`#${value}`)
                    }}
                />
            }
            example={`
<Breadcrumbs
    value='Categories'
    values={[
        { label: 'Home', value: 'homepage' },
        { label: 'Categories', value: 'categories?filter=all' },
        { label: 'Technology', value: 'categories/tech' },
    ]}
    onClick={value => {
        // ...
    }}
/>`}
            properties={[
                {
                    name: 'value',
                    type: 'STRING',
                    description: `The current value`,
                },
                {
                    name: 'values',
                    type: 'ARRAY',
                    required: true,
                    description: 'The array of breadcrumb values',
                },
                {
                    name: '→',
                    content: 'label',
                    required: true,
                    type: 'STRING',
                    description: `Label to show`,
                },
                {
                    name: '→',
                    content: 'value',
                    required: true,
                    type: 'STRING',
                    description: `In case you want the breadcrumbs to use React Router, this will be the base for the link "to" property`,
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description:
                        'Callback function that gets passed the selected "value"',
                },
            ]}
        />
    )
}

export default BreadcrumbsTemplate
