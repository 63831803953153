import React from 'react'
import Template from '../layout/Template'
import {
    Form,
    FormItem,
    FormItemTypes,
    FormSection,
    FormSectionSizes,
    IconTypes,
} from 'rds'

const FormSectionTemplate = () => {
    return (
        <Template
            hashValue='form-section'
            componentName='FormSection'
            overview={
                <div>
                    <Form
                        className='rds-full-block'
                        submitCallback={() => alert('Hi there!')}
                    >
                        <FormSection
                            title='Big form section'
                            description='This is the main section of the form'
                            size={FormSectionSizes.BIG}
                        >
                            <FormItem
                                icon={IconTypes.USER}
                                label='Item'
                                placeholder='A standard text input...'
                            />
                        </FormSection>
                        <FormSection
                            title='Medium form section'
                            description='This is a medium section'
                            size={FormSectionSizes.MEDIUM}
                        >
                            <FormItem
                                icon={IconTypes.USER}
                                label='Item'
                                placeholder='A standard text input...'
                            />
                            <FormItem
                                label='Item'
                                type={FormItemTypes.SELECT}
                                values={[
                                    {
                                        label: 'Option 1',
                                        id: 1,
                                    },
                                    {
                                        label: 'Option 2',
                                        id: 2,
                                    },
                                    {
                                        label: 'Option 3',
                                        id: 3,
                                    },
                                    {
                                        label: 'Option 4',
                                        id: 4,
                                    },
                                ]}
                            />
                        </FormSection>
                        <FormSection
                            title='Small form section'
                            description='This is a small section'
                            size={FormSectionSizes.SMALL}
                        >
                            <FormItem
                                icon={IconTypes.USER}
                                label='Item'
                                placeholder='A standard text input...'
                            />
                        </FormSection>
                    </Form>
                </div>
            }
            example={`
<FormSection
    title='Some title'
    description='This is the main section of the form'
    size={FormSectionSizes.SMALL}
>
    // <FormItem label='Some input' />
</FormSection>`}
            properties={[
                {
                    name: 'title',
                    type: 'STRING',
                },
                {
                    name: 'description',
                    type: 'STRING',
                },
                {
                    name: 'children',
                    type: 'FUNCTION | Node',
                    description:
                        'In case you want to customize the content of the FormItem, you can use the children prop',
                },
                {
                    name: 'size',
                    type: 'FormSectionSizes',
                    description:
                        'Size of the FormItem, you can use multiple sizes to display your components differently',
                },
            ]}
        />
    )
}

export default FormSectionTemplate
