import React from 'react'
import Header from './Header'
import Block from './Block'
import Card from './Card'
import Icon from './Icon'
import { ThemeVariants } from '../constants'

const Collapsible = ({
    id,
    className,
    headerTitle,
    headerText,
    headerSize,
    children,
    onClick,
    onMouseOver,
    onMouseLeave,
}) => {
    const [expanded, setExpanded] = React.useState()

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick()
        }
        setExpanded(!expanded)
    }

    return (
        <div id={id} className={`rds-collapsible ${className || ''}`}>
            <Card
                className='rds-collapsible_header'
                onClick={handleClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                <div className='rds-flex algin-center rds-justify-between'>
                    <Header
                        className='rds-full-block'
                        title={headerTitle}
                        text={headerText}
                        size={headerSize}
                        style={{ width: '90%' }}
                    />
                    <div className='rds-flex rds-align-center'>
                        <Icon
                            className='rds-collapsible_chevron'
                            icon='Down'
                            size={25}
                            style={{
                                transform: `rotate(${expanded ? 180 : 0}deg)`,
                            }}
                        />
                    </div>
                </div>
            </Card>
            <Block
                variant={ThemeVariants.GRAY}
                className={`rds-full-block rds-p_around__md rds-collapsible_content ${
                    expanded ? 'rds-collapsible_content__expanded' : ''
                }`}
                roundedBorder
            >
                {children}
            </Block>
        </div>
    )
}

export default Collapsible
