import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { FormItemTypes, ThemeVariants } from '../../constants'
import {
    DEFAULT_CONFIG,
    getInputBaseRules,
} from '../../constants/styledComponents'
import { variantBackgroundValue } from '../../constants/cssRules'

const StyledInput = styled.input.withConfig(DEFAULT_CONFIG)(props => {
    const isColorPicker = props.type === FormItemTypes.COLOR
    const rules = {
        ...getInputBaseRules(props),
        cursor: isColorPicker && !props.disabled ? 'pointer' : null,
        position: isColorPicker ? 'relative' : null,
    }
    if (isColorPicker) {
        rules['&:after'] = {
            content: '""',
            top: '-10%',
            left: '-10%',
            position: 'absolute',
            width: '120%',
            height: '120%',
            backgroundColor: props.disabled
                ? variantBackgroundValue({
                      ...props,
                      variant: ThemeVariants.GRAY,
                  })
                : props.value || 'white',
        }
    }
    return rules
})

const Input = props => {
    const themeContext = React.useContext(ThemeContext)
    return <StyledInput {...props} themeContext={themeContext} />
}

export default Input
