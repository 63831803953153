import React from 'react'
import Template from '../layout/Template'
import { Emoji, EmojiTypes } from 'rds'

const EmojiTemplate = () => {
    return (
        <Template
            hashValue='emoji'
            componentName='Emoji'
            overview={
                <div className='rds-flex rds-align-center rds-justify-evenly'>
                    <Emoji type={EmojiTypes.ALERT} />
                    <Emoji type={EmojiTypes.CALENDAR} />
                    <Emoji type={EmojiTypes.CONFETTI} />
                    <Emoji type={EmojiTypes.DELIVERED} />
                    <Emoji type={EmojiTypes.PAID} />
                    <Emoji type={EmojiTypes.WARNING} />
                </div>
            }
            example={`
<Emoji
    type={EmojiTypes.<EMOJI_TYPE>}
    title={String}
/>`}
            properties={[
                {
                    name: 'type',
                    type: 'EmojiTypes',
                    description: "import { EmojiTypes } from 'rds'",
                    required: true,
                },
                {
                    name: 'title',
                    type: 'STRING',
                    description: 'HTML title attribute',
                },
            ]}
        />
    )
}

export default EmojiTemplate
