import React from 'react'
import styled from 'styled-components'
import { TypographyTypes } from '../../constants'
import Isologo from './Isologo'
import Typography from '../Typography'

const StyledLogo = styled.div({
    '& .rds-isologo': {
        marginTop: 2,
    },
})

const Logo = ({ className, variant }) => {
    return (
        <StyledLogo className={`rds-logo ${className || ''}`}>
            <Typography
                type={TypographyTypes.H1}
                className='rds-logo rds-flex rds-align-center'
                variant={variant}
            >
                <Isologo className='rds-m_right__sm' />
                <span>reango</span>
            </Typography>
        </StyledLogo>
    )
}

export default Logo
