import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { colorValue } from '../../constants/cssRules'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'

const SEPARATOR = '/'

const StyledBreadcrumbValue = styled.span.withConfig(DEFAULT_CONFIG)(props => ({
    fontSize: 14,
    cursor: 'pointer',
    color: colorValue(props),
    fontWeight: props.active ? 'bolder' : null,
}))
const StyledBreadcrumbSeparator = styled.span.withConfig(DEFAULT_CONFIG)(
    props => ({
        fontSize: 14,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'default',
        color: colorValue(props),
    }),
)

const Breadcrumb = ({ active, children, isLastOne, value, onClick }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <div
            className='rds-breadcrumbs_breadcrumb'
            onClick={
                typeof onClick === 'function' ? () => onClick(value) : null
            }
        >
            <span className='rds-flex rds-align-center'>
                <StyledBreadcrumbValue
                    className='rds-text-variant_link'
                    themeContext={themeContext}
                    active={active}
                >
                    {children}
                </StyledBreadcrumbValue>
                {isLastOne ? null : (
                    <StyledBreadcrumbSeparator themeContext={themeContext}>
                        {SEPARATOR}
                    </StyledBreadcrumbSeparator>
                )}
            </span>
        </div>
    )
}

export default Breadcrumb
