import React from 'react'
import { ThemeVariants, HeaderSizes, ButtonVariants } from '../../../constants'
import Block from '../../Block'
import Button from '../../Button'
import Card from '../../Card'

const FilesPickerFile = ({ fileData, removeCallback }) => {
    return (
        <Block
            className='rds-files-picker_file'
            roundedBorder
            variant={ThemeVariants.GRAY}
        >
            <Button
                className='rds-files-picker_file-actions-btn'
                icon='Delete'
                variant={ButtonVariants.DANGER}
                onClick={removeCallback}
            />
            {fileData.file.type && fileData.file.type.includes('image') ? (
                <div>
                    <img
                        className='rds-files-picker_file-preview-shadow'
                        src={fileData.preview}
                        alt={fileData.filename}
                        title={fileData.filename}
                    />
                    <img
                        className='rds-files-picker_file-preview'
                        src={fileData.preview}
                        alt={fileData.filename}
                        title={fileData.filename}
                    />
                </div>
            ) : (
                <Card
                    className='rds-files-picker_file-preview'
                    headerTitle={fileData.filename}
                    headerText={fileData.file.type}
                    headerSize={HeaderSizes.XSMALL}
                />
            )}
        </Block>
    )
}

export default FilesPickerFile
