import React from 'react'
import { ThemeContext } from 'styled-components'
import { Icons, ThemeVariants } from '../../constants'
import Block from '../Block'
import Icon from '../Icon'

const Checkbox = ({ id, className, disabled, onClick, value }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <div
            id={id}
            className={`rds-form-item_checkbox rds-flex rds-align-center ${
                className || ''
            } ${value ? 'active' : ''} ${
                disabled ? 'rds-form-item_disabled' : ''
            }`}
            onClick={disabled ? null : onClick}
        >
            <Block
                variant={ThemeVariants.GRAY}
                disabled={disabled}
                className='rds-form-item_checkbox-inner rds-m_right__sm'
                style={{
                    borderRadius: themeContext.borderRadius,
                }}
                bordered
            >
                <Icon
                    className='rds-form-item_checkbox-icon'
                    icon={Icons.SAVE}
                    variant={ThemeVariants.WHITE}
                    style={{
                        backgroundColor: themeContext.main,
                        borderRadius: themeContext.borderRadius,
                    }}
                />
            </Block>
        </div>
    )
}

export default Checkbox
