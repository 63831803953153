import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { borderRadiusValue } from '../../constants/cssRules'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'

const StyledButtonGroup = styled.div.withConfig(DEFAULT_CONFIG)(props => {
    const border = props.size > 2 ? 0 : null
    return {
        '> *': {
            '&.rds-btn, & .rds-btn': {
                margin: 0,
                borderRadius: 0,
            },
            '&:first-child.rds-btn, &:first-child .rds-btn': {
                borderTopLeftRadius: borderRadiusValue(props),
                borderBottomLeftRadius: borderRadiusValue(props),
                borderRight: border,
            },
            '&:last-child.rds-btn, &:last-child .rds-btn': {
                borderTopRightRadius: borderRadiusValue(props),
                borderBottomRightRadius: borderRadiusValue(props),
                borderLeft: border,
            },
        },
    }
})

const ButtonGroup = props => {
    const { children, className } = props
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledButtonGroup
            {...props}
            themeContext={themeContext}
            className={`rds-btn-group rds-flex rds-align-center rds-overflow_hidden rds-width_fit-content ${className || ''}`}
            size={React.Children.count(children)}
        >
            {children}
        </StyledButtonGroup>
    )
}

export default ButtonGroup
