import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
    DEFAULT_CONFIG,
    getInputBaseRules,
} from '../../constants/styledComponents'

const StyledTextarea =
    styled.textarea.withConfig(DEFAULT_CONFIG)(getInputBaseRules)

const Textarea = props => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledTextarea
            {...props}
            themeContext={themeContext}
            autoComplete='off'
        />
    )
}

export default Textarea
