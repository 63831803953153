import React from 'react'
import {
    Typography,
    TypographyTypes,
    ThemeVariants,
    HeaderSizes,
    Block,
    Card,
} from 'rds'
import Template from '../layout/Template'

const TypographyTemplate = () => {
    return (
        <Template
            hashValue='typography'
            componentName='Typography'
            overview={
                <div>
                    <Card
                        headerTitle='Sizes'
                        headerText='You can apply different sizes to Typography components'
                        headerSize={HeaderSizes.SMALL}
                    >
                        <Typography type={TypographyTypes.H1}>
                            Big title
                        </Typography>
                        <Typography
                            type={TypographyTypes.H2}
                            className='rds-m_top__sm'
                        >
                            Medium title
                        </Typography>
                        <Typography
                            type={TypographyTypes.H3}
                            className='rds-m_top__sm'
                        >
                            Small title
                        </Typography>
                        <Typography
                            type={TypographyTypes.H4}
                            className='rds-m_top__sm'
                        >
                            Extra small title
                        </Typography>
                        <Typography
                            type={TypographyTypes.P}
                            className='rds-m_top__sm'
                        >
                            Normal text
                        </Typography>
                    </Card>
                    <Card
                        headerTitle='Variants'
                        headerText='You can apply variants to Typography components'
                        headerSize={HeaderSizes.SMALL}
                        className='rds-m_top__sm'
                    >
                        <Typography
                            className='rds-m_top__sm'
                            type={TypographyTypes.H3}
                            variant={ThemeVariants.MAIN}
                        >
                            Main variant
                        </Typography>
                        <Typography
                            className='rds-m_top__sm'
                            type={TypographyTypes.H3}
                            variant={ThemeVariants.SECONDARY}
                        >
                            Secondary variant
                        </Typography>
                        <Typography
                            className='rds-m_top__sm'
                            type={TypographyTypes.H3}
                            variant={ThemeVariants.ERROR}
                        >
                            Error variant
                        </Typography>
                        <Typography
                            className='rds-m_top__sm'
                            type={TypographyTypes.H3}
                            variant={ThemeVariants.WARNING}
                        >
                            Warning variant
                        </Typography>
                        <Block
                            variant={ThemeVariants.DARK}
                            style={{ display: 'inline-block' }}
                            className='rds-m_top__sm rds-p_around__sm'
                        >
                            <Typography
                                type={TypographyTypes.H3}
                                variant={ThemeVariants.WHITE}
                            >
                                White variant
                            </Typography>
                        </Block>
                        {/* eslint-disable-next-line */}
                        <a href='#' className='rds-full-block rds-m_top__sm'>
                            <Typography
                                className='rds-text-variant_link'
                                type={TypographyTypes.H3}
                                variant={ThemeVariants.MAIN}
                                style={{ display: 'inline-block' }}
                            >
                                Main variant with link
                            </Typography>
                        </a>
                    </Card>
                </div>
            }
            example={`
<Typography
    type={TypographyTypes.H1}
>
    // A Big title
</Typography>
<Typography
    type={TypographyTypes.P}
>
    // Some normal text
</Typography>
<Typography
    type={TypographyTypes.H4}
    variant={ThemeVariants.<VARIANT>}
>
    // Extra small title with variant
</Typography>`}
            properties={[
                {
                    name: 'type',
                    type: 'TypographyTypes',
                    description: "import { TypographyTypes } from 'rds'",
                    required: true,
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'HTML class attribute',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'HTML style attribute',
                },
                {
                    name: 'variant',
                    type: 'ThemeVariants',
                    description: "import { ThemeVariants } from 'rds'",
                },
            ]}
        />
    )
}

export default TypographyTemplate
