import React from 'react'
import {
    Header,
    HeaderSizes,
    CodeBlock,
    Table,
    Typography,
    TypographyTypes,
    ThemeVariants,
    TableItemTypes,
    Card,
} from 'rds'
import { Link } from 'react-router-dom'

const Template = ({
    hashValue,
    componentName,
    example,
    properties,
    overview,
}) => {
    return (
        <div id={hashValue} className='rds-full-block rds-p_bottom__md'>
            <div>
                <Link to='/#components'>
                    <Typography
                        type={TypographyTypes.P}
                        variant={ThemeVariants.MAIN}
                    >
                        <b>← Components</b>
                    </Typography>
                </Link>
                <Header title={componentName} />
            </div>
            {overview ? (
                <Card
                    className='rds-full-block rds-m_top__md rds-overflow_visible'
                    headerTitle='Example'
                    headerText='This is how the component looks like'
                    headerSize={HeaderSizes.SMALL}
                    style={{ zIndex: 1 }}
                >
                    {overview}
                </Card>
            ) : null}
            <Card
                className='rds-full-block rds-m_top__md'
                headerTitle='Usage'
                headerText='First import the component, then use the API properties provided'
                headerSize={HeaderSizes.SMALL}
            >
                <CodeBlock className='rds-m_top__sm'>
                    {`import { ${componentName} } from 'rds'
                ${example}`}
                </CodeBlock>
            </Card>
            <Table
                title='API'
                description={`These are the props available for the ${componentName} component`}
                className='rds-full-block rds-m_top__md'
                columnNames={[
                    {
                        label: 'Name',
                        fieldName: 'name',
                        type: TableItemTypes.TAG,
                    },
                    {
                        label: 'Nested property',
                        fieldName: 'content',
                        type: TableItemTypes.TAG,
                        options: {
                            variant: value => {
                                return value ? null : ThemeVariants.TRANSPARENT
                            },
                        },
                    },
                    {
                        label: 'Required',
                        fieldName: 'requiredTag',
                        type: TableItemTypes.TAG,
                        options: {
                            variant: value => {
                                return value === 'Required'
                                    ? ThemeVariants.WARNING
                                    : null
                            },
                        },
                    },
                    {
                        label: 'Type',
                        fieldName: 'type',
                        type: TableItemTypes.TAG,
                    },
                    {
                        label: 'Description',
                        fieldName: 'description',
                    },
                ]}
                rows={properties.map(prop => ({
                    ...prop,
                    requiredTag: prop.required ? 'Required' : 'Optional',
                }))}
            />
        </div>
    )
}

export default Template
