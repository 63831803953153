import React from 'react'
import { ThemeVariants, TypographyTypes } from '../../constants'
import Card from '../Card'
import Typography from '../Typography'

const ChatMessage = ({ sessionUserId, message }) => {
    const isMine =
        sessionUserId === (message.createdBy._id || message.createdBy)
    return (
        <div
            style={{
                width: 'fit-content',
                float: isMine ? 'right' : undefined,
            }}
        >
            <Card
                style={{
                    padding: 'var(--rds-spacingSmall)',
                }}
                variant={isMine ? undefined : ThemeVariants.DARK}
            >
                <Typography
                    type={TypographyTypes.P}
                    style={{ whiteSpace: 'pre-line' }}
                    variant={isMine ? undefined : ThemeVariants.WHITE}
                >
                    {message.text}
                </Typography>
            </Card>
            <Typography
                type={TypographyTypes.P}
                style={{
                    opacity: 0.5,
                }}
                className={`rds-full-block rds-flex justify-${
                    isMine ? 'start' : 'end'
                }`}
            >
                <b className='rds-m_right__sm' style={{ fontSize: '80%' }}>
                    {isMine ? 'Yo' : message.createdBy.name}
                </b>
                <span style={{ fontSize: '80%' }}>
                    {new Date(message.createdAt).toLocaleString()}
                </span>
            </Typography>
        </div>
    )
}

export default React.memo(ChatMessage)
