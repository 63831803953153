import React from 'react'
import GettingStarted from './GettingStarted'
import { TableItem } from 'rds'
import Css from './CSS'
import Components from './Components'
import Theming from './Theming'
import Changelog from './Changelog'
import Troubleshoot from './Troubleshoot'
import Donate from './Donate'

const Main = () => {
    return (
        <React.Fragment>
            <GettingStarted />
            <TableItem />
            <Components />
            <TableItem />
            <Theming />
            <TableItem />
            <Css />
            <TableItem />
            <Troubleshoot />
            <TableItem />
            <Changelog />
            <TableItem />
            <Donate />
        </React.Fragment>
    )
}

export default Main
