import React from 'react'
import Template from '../layout/Template'
import { Table, ThemeVariants, TableItemTypes } from 'rds'

const TableTemplate = () => {
    const data = [
        {
            picture:
                'http://res.cloudinary.com/dnl5ck7y0/image/upload/v1632607190/salad-remix/products/zmcz3qf5alnivgczoepe.jpg',
            name: 'Item #1',
            description: 'This is my first item description',
            price: '$ 120',
            stock: 4020,
            isAtSale: true,
        },
        {
            picture:
                'http://res.cloudinary.com/dnl5ck7y0/image/upload/v1632603243/salad-remix/products/xu5xlhr6rwvdacvo4sp3.jpg',
            name: 'Item #2',
            description: 'This is my second item description',
            price: '$ 80',
            stock: 240,
            isAtSale: false,
        },
        {
            picture:
                'http://res.cloudinary.com/dnl5ck7y0/image/upload/v1636557020/salad-remix/products/auquyl0bqkjtdogrylmk.jpg',
            name: 'Item #3',
            description: 'This is my third item description',
            price: '$ 310',
            stock: 0,
            isAtSale: true,
        },
    ]
    return (
        <Template
            hashValue='tables'
            componentName='Table'
            overview={
                <Table
                    title='My title'
                    description='A brief description of what I am showing here'
                    refreshAction={() => alert('This is the refresh action!')}
                    columnNames={[
                        {
                            label: 'Picture',
                            fieldName: 'picture',
                            type: TableItemTypes.IMAGE,
                        },
                        {
                            label: 'Name',
                            fieldName: 'name',
                            type: TableItemTypes.LINK,
                            options: {
                                link: {
                                    base: '/components/table',
                                    recordFieldName: 'name',
                                },
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                        {
                            label: 'Price',
                            fieldName: 'price',
                            type: TableItemTypes.TAG,
                        },
                        {
                            label: 'Stock',
                            fieldName: 'stock',
                            type: TableItemTypes.TAG,
                        },
                        {
                            label: 'On sale',
                            fieldName: 'isOnSaleBadge',
                            type: TableItemTypes.TAG,
                            options: {
                                variant: value => {
                                    if (value === 'On sale!') {
                                        return ThemeVariants.PURPLE
                                    }
                                    return ThemeVariants.ERROR
                                },
                            },
                        },
                    ]}
                    rows={data.map(item => ({
                        ...item,
                        isOnSaleBadge: item.isAtSale
                            ? 'On sale!'
                            : 'Not at the moment',
                    }))}
                />
            }
            example={`
<Table
    title='My title'
    description='A brief description of what I am showing here'
    refreshAction={() => alert('This is the refresh action!')}
    columnNames={[
        {
            label: 'Picture',
            fieldName: 'picture',
            type: TableItemTypes.IMAGE,
        },
        {
            label: 'Name',
            fieldName: 'name',
            type: TableItemTypes.LINK,
            options: {
                link: {
                    base: '/components/table',
                    recordFieldName: 'name',
                },
            },
        },
        {
            label: 'Description',
            fieldName: 'description',
        },
        {
            label: 'Price',
            fieldName: 'price',
            type: TableItemTypes.TAG,
        },
        {
            label: 'Stock',
            fieldName: 'stock',
            type: TableItemTypes.TAG,
        },
        {
            label: 'At sale',
            fieldName: 'isOnSaleBadge',
            type: TableItemTypes.TAG,
            options: {
                variant: value => {
                    if (value === 'At sale!') {
                        return ThemeVariants.PURPLE
                    }
                    return ThemeVariants.ERROR
                },
            },
        },
    ]}
    rows={data.map(item => ({
        ...item,
        isOnSaleBadge: item.isAtSale
            ? 'At sale!'
            : 'Not at the moment',
    }))}
/>
            `}
            properties={[
                {
                    name: 'title',
                    type: 'STRING',
                    description: 'A title to show in the Table',
                },
                {
                    name: 'description',
                    type: 'STRING',
                    description: 'A description to show below the title',
                },
                {
                    name: 'refreshAction',
                    type: 'FUNCTION',
                    description:
                        'Callback to execute when refresh button is clicked',
                },
                {
                    name: 'columnNames',
                    type: 'ARRAY',
                    description: 'An array of settings for every row property',
                    required: true,
                },
                {
                    name: 'columnNames.columnName',
                    content: '',
                    type: 'OBJECT',
                    description: 'Settings for every row property',
                },
                {
                    name: '→',
                    content: 'label',
                    type: 'STRING',
                    description:
                        'The column label to show, this can be totally different from the property name',
                    required: true,
                },
                {
                    name: '→',
                    content: 'fieldName',
                    type: 'STRING',
                    description: 'Exact property name of the row object',
                    required: true,
                },
                {
                    name: '→',
                    content: 'options',
                    type: 'OBJECT',
                    description: "Options for the row's property",
                },
                {
                    name: '→',
                    content: 'options.tagged',
                    type: 'BOOLEAN | OBJECT',
                    description: 'If true, a default Tag will be shown',
                },
                {
                    name: '→',
                    content: 'options.tagged.variant',
                    type: 'ThemeVariants',
                    description: "import { ThemeVariants } from 'rds'",
                },
                {
                    name: '→',
                    content: 'options.boolean',
                    type: 'OBJECT',
                    description:
                        'If the property is Boolean, you can use different display settings for it',
                },
                {
                    name: '→',
                    content: 'options.boolean.values',
                    type: 'OBJECT',
                    required: true,
                    description: '',
                },
                {
                    name: '→',
                    content: 'options.boolean.values.true',
                    type: 'ANY',
                    required: true,
                    description:
                        'Something to show in case the value is TRUE, it supports JSX and String',
                },
                {
                    name: '→',
                    content: 'options.boolean.values.false',
                    type: 'ANY',
                    required: true,
                    description:
                        'Something to show in case the value is FALSE, it supports JSX and String',
                },
                {
                    name: '→',
                    content: 'options.boolean.falseState',
                    type: 'BOOLEAN',
                    description:
                        'In case the value is FALSE, and this setting is set to TRUE, the value will be set as "disabled" with a middle opacity and a grayscale filter',
                },
                {
                    name: '→',
                    content: 'options.image',
                    type: 'BOOLEAN',
                    description:
                        'When set to true, this column will display an image element. In order to properly work, the row value on this property should be the image URL.',
                },
                {
                    name: '→',
                    content: 'options.dateValue',
                    type: 'BOOLEAN',
                    description:
                        'When set to true, this column will be displayed as a date.',
                },
            ]}
        />
    )
}

export default TableTemplate
