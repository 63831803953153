import React from 'react'
import { Card, CodeBlock, Graph, Header, HeaderSizes, ThemeVariants } from 'rds'

const Troubleshoot = () => {
    return (
        <section id='troubleshoot' className='rds-m_top'>
            <Header
                title='Troubleshoot'
                text='Every component has an integrated troubleshooting state that provides information about the props that are wrong or missing'
            />
            <div className='rds-m_top__md'>
                <Header
                    title="What you'll see"
                    text='This message will list all the required fields for the component you are trying to use'
                    size={HeaderSizes.SMALL}
                />
                <Graph />
            </div>
            <div className='rds-m_top__md'>
                <Header
                    title='Your code'
                    text='As you can see the Graph component is missing some required props'
                    size={HeaderSizes.SMALL}
                />
                <CodeBlock className='rds-m_top__sm'>
                    {`// Lacking required props...
<Graph
    itemFieldName='value'
    itemDescriptionFieldName='description'
/>`}
                </CodeBlock>
                <Card
                    className='rds-m_top__sm'
                    headerTitle='In case you do not get this information'
                    headerText='You always have the posibility of checking the docs at Components.'
                    variant={ThemeVariants.WARNING}
                    headerSize={HeaderSizes.XSMALL}
                />
            </div>
        </section>
    )
}

export default Troubleshoot
