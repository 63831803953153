import React from 'react'
import styled from 'styled-components'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import { getTypographyDefaultConfig } from '../../utils/typography.util'

const StyledH3 = styled.h3.withConfig(DEFAULT_CONFIG)(props => ({
    ...getTypographyDefaultConfig(props),
    fontSize: 16,
}))

const H3 = props => (
    <StyledH3
        {...props}
        style={
            props.onClick ? { cursor: 'pointer', ...props.style } : props.style
        }
    >
        {props.children}
    </StyledH3>
)

export default H3
