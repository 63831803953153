import React from 'react'
import Template from '../layout/Template'
import { Card, HeaderSizes, Rater } from 'rds'

const VALUES = Object.freeze([
    'The worst',
    'Not so good',
    'Acceptable',
    'Good',
    'Excellent!',
])

const RaterTemplate = () => {
    return (
        <Template
            hashValue='rater'
            componentName='Rater'
            overview={
                <div>
                    <Card
                        headerTitle='Interactive'
                        headerSize={HeaderSizes.XSMALL}
                    >
                        <Rater
                            initial={3}
                            values={VALUES}
                            onChange={() => {}}
                            showLabel
                        />
                    </Card>
                    <Card
                        headerTitle='Read-only'
                        headerSize={HeaderSizes.XSMALL}
                        className='rds-m_top__sm'
                    >
                        <Rater initial={0} values={VALUES} showLabel />
                    </Card>
                    <Card
                        headerTitle='No label'
                        headerSize={HeaderSizes.XSMALL}
                        className='rds-m_top__sm'
                    >
                        <Rater initial={4} values={VALUES} />
                    </Card>
                </div>
            }
            example={`
<Rater
    values={[
        'The worst',
        'Not so good',
        'Acceptable',
        'Good',
        'Excellent!',
    ]}
    onChange={rate => {}}
    showLabel
/>`}
            properties={[
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class string',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'values',
                    type: 'ARRAY',
                    required: true,
                    description:
                        'Array of Strings that represents the different values that will match the stars. The length of it will determine the number of stars',
                },
                {
                    name: 'onChange',
                    type: 'FUNCTION',
                    description:
                        'Callback to execute when the rate has changed. If this is not provided, but an "initial" value is provided, the component will work as a simple visualizer',
                },
                {
                    name: 'initial',
                    type: 'NUMBER',
                    description: 'Index of the initial value',
                },
                {
                    name: 'size',
                    type: 'FormItemSizes',
                    description:
                        'Size of the component, only BIG is accepted by the moment. Leave empty for default size',
                },
                {
                    name: 'showLabel',
                    type: 'BOOLEAN',
                    description:
                        'If true, the component will try to extract the current value form the "values" Array of Strings and show it',
                },
            ]}
        />
    )
}

export default RaterTemplate
