import React from 'react'
import styled from 'styled-components'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import { getTypographyDefaultConfig } from '../../utils/typography.util'

const StyledP = styled.p.withConfig(DEFAULT_CONFIG)(props => ({
    ...getTypographyDefaultConfig(props),
    fontSize: 15,
}))

const P = ({
    variant,
    themeContext,
    className,
    children,
    align,
    onClick,
    style,
}) => {
    return (
        <StyledP
            align={align}
            themeContext={themeContext}
            className={className}
            onClick={onClick && onClick}
            style={onClick ? { ...style, cursor: 'pointer' } : style}
            variant={variant}
        >
            {children}
        </StyledP>
    )
}

export default P
