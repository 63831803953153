import * as cssRules from './cssRules'

const PROP_BLACKLIST = Object.freeze([
    'active',
    'animated',
    'big',
    'bordered',
    'currentStep',
    'hasContent',
    'hasError',
    'isActive',
    'isClicked',
    'isMobile',
    'isPanelVisible',
    'itemWidth',
    'linear',
    'noBorderRadius',
    'noLine',
    'onFetch',
    'roundedBorder',
    'sessionUserId',
    'themeContext',
    'useInterval',
    'visible',
])

export const DEFAULT_CONFIG = {
    shouldForwardProp: prop => !PROP_BLACKLIST.includes(prop),
}

/**
 * Generates the base styling rules for an input component based on the provided properties.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.disabled - Indicates if the input is disabled.
 * @param {boolean} props.hasError - Indicates if the input has an error.
 * @returns {Object} The base styling rules for the input component.
 */
export const getInputBaseRules = props => {
    const hoveredBorder = props.disabled
        ? null
        : cssRules.borderValue(props, true, props.hasError)
    return {
        background: cssRules.backgroundValue(props),
        border: cssRules.borderValue(props, false, props.hasError),
        borderRadius: cssRules.borderRadiusValue(props),
        color: cssRules.colorValue(props),
        fontSize: 14,
        '&:hover': {
            border: hoveredBorder,
        },
        '&:focus': {
            outline: hoveredBorder,
        },
    }
}
