import React from 'react'
import DatePicker from 'react-datepicker'
import styled, { ThemeContext } from 'styled-components'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import {
    blackValue,
    borderRadiusValue,
    COLOR_WHITE,
} from '../../constants/cssRules'
import { ThemeVariants, FormItemTypes, TypographyTypes } from '../../constants'

import Select from './Select'
import Input from '../Form/Input'
import Textarea from '../Form/Textarea'
import FilesPicker from './FilesPicker'
import Block from '../Block'
import MultiSelect from './MultiSelect'
import Icon from '../Icon'
import Checkbox from './Checkbox'
import Label from './../Label'
import Lookup from './Lookup'
import Typography from '../Typography'
import TagMultiSelect from './TagMultiSelect'

const StyledFormItemTooltipInner = styled.span.withConfig(DEFAULT_CONFIG)(
    props => ({
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 'max-content',
        backgroundColor: blackValue(props.themeContext),
        borderRadius: borderRadiusValue(props),
        color: COLOR_WHITE,
        fontSize: '0.8rem',
        paddingTop: 2,
        paddingBottom: 2,
        visibility: 'hidden',
    }),
)

const FormItem = ({
    accept,
    ariaLabel,
    autoComplete,
    autoFocus,
    currentValue,
    children,
    disabled,
    id,
    label,
    onChange,
    onClick,
    onSearch,
    placeholder,
    required,
    size,
    type,
    values,
    value,
    tooltip,
    uploadLabel,
    errorMessage,
    minLength,
    multiple,
    className,
    style,
    min,
    max,
    step,
    icon,
    forceDropdown,
    pattern,
    withAmounts,
    withCounter,
    resultsLabel,
    selectedLabel,
    deselectAllLabel,
    noResultsLabel,
}) => {
    const tooltipId = id ? `${id}--tooltip` : null
    const isLabelled = icon || label || tooltip
    const isCheckbox = type === FormItemTypes.CHECKBOX
    const showSelectedOptionsCount =
        type === FormItemTypes.MULTI_SELECT && withCounter && max
    const themeContext = React.useContext(ThemeContext)
    const [currentOption, setCurrentOption] = React.useState(null)
    const [active, setActive] = React.useState(false)
    const [focusing, setFocusing] = React.useState(false)

    React.useEffect(() => {
        setCurrentOption(currentValue)
    }, [currentValue])

    const getDOMChangeEventPayload = value => ({
        target: { id, value },
    })

    const sendDOMChangeEvent = value => {
        if (typeof onChange === 'function') {
            onChange(getDOMChangeEventPayload(value))
        }
    }

    const handleSelect = selectedValue => {
        setCurrentOption(selectedValue)
        sendDOMChangeEvent(selectedValue)
    }

    const handleDatePick = date => {
        sendDOMChangeEvent(date)
    }

    const handleCheckboxClick = () => {
        sendDOMChangeEvent(!value)
    }

    const getFormItemType = () => {
        let jsx
        switch (type) {
            case FormItemTypes.TAG_MULTI_SELECT:
                jsx = (
                    <TagMultiSelect
                        id={id}
                        onSelect={valueId => handleSelect(valueId)}
                        values={values}
                        value={value}
                        max={max}
                        disabled={disabled}
                    />
                )
                break

            case FormItemTypes.MULTI_SELECT:
                jsx = (
                    <MultiSelect
                        id={id}
                        onSelect={valueId => handleSelect(valueId)}
                        values={values}
                        value={value}
                        max={max}
                        disabled={disabled}
                        withCounter={withCounter}
                    />
                )
                break

            case FormItemTypes.SELECT:
                jsx = (
                    <Select
                        values={values}
                        value={value}
                        currentValue={currentOption}
                        id={id}
                        onSelect={valueId => handleSelect(valueId)}
                        placeholder={placeholder}
                        disabled={disabled}
                        forceDropdown={forceDropdown}
                    />
                )
                break

            case FormItemTypes.CARD_SELECT:
                jsx = (
                    <Select
                        values={values}
                        value={value}
                        currentValue={currentOption}
                        id={id}
                        onSelect={valueId => handleSelect(valueId)}
                        cardsUI
                    />
                )
                break

            case FormItemTypes.FILES_PICKER:
                jsx = (
                    <FilesPicker
                        id={id}
                        accept={accept}
                        label={label}
                        uploadLabel={uploadLabel}
                        multiple={multiple}
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                    />
                )
                break

            case FormItemTypes.DATE_PICKER:
                jsx = (
                    <Block
                        variant={ThemeVariants.GRAY}
                        className='rds-flex'
                        roundedBorder
                        bordered
                    >
                        <DatePicker
                            id={id}
                            selected={value || null}
                            onChange={handleDatePick}
                            autoComplete='off'
                            className='rds-flex'
                        />
                    </Block>
                )
                break

            case FormItemTypes.CHECKBOX:
                jsx = null
                break

            case FormItemTypes.LOOKUP:
                jsx = (
                    <Lookup
                        autoFocus={autoFocus}
                        onSearch={onSearch}
                        id={id}
                        errorMessage={errorMessage}
                        icon={icon}
                        label={label}
                        tooltip={tooltip}
                        onChange={onChange}
                        multiple={multiple}
                        value={value}
                        withAmounts={withAmounts}
                        resultsLabel={resultsLabel}
                        selectedLabel={selectedLabel}
                        deselectAllLabel={deselectAllLabel}
                        noResultsLabel={noResultsLabel}
                    />
                )
                break

            case FormItemTypes.TEXTAREA:
                jsx = (
                    <Textarea
                        aria-label={ariaLabel}
                        aria-describedby={tooltipId}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        id={id}
                        onClick={onClick}
                        onChange={onChange}
                        type={type}
                        min={min}
                        value={value}
                        minLength={minLength}
                        required={required}
                        placeholder={placeholder}
                        onFocus={() => setActive(true)}
                        onBlur={() => setActive(false)}
                        disabled={disabled}
                        hasError={errorMessage}
                    >
                        {children}
                    </Textarea>
                )
                break

            default:
                jsx = (
                    <Input
                        aria-label={ariaLabel}
                        aria-describedby={tooltipId}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        id={id}
                        onClick={onClick}
                        onChange={event => {
                            if (typeof onChange !== 'function') {
                                return
                            }
                            if (
                                type === FormItemTypes.NUMBER &&
                                event.target.value
                            ) {
                                // If the input type is number, format the output
                                // to be an integer
                                event.target.value = Number(event.target.value)
                            }
                            onChange(event)
                        }}
                        type={type}
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        minLength={minLength}
                        required={required}
                        placeholder={placeholder}
                        onBlur={() => setActive(false)}
                        onFocus={() => setActive(true)}
                        disabled={disabled}
                        pattern={pattern}
                        hasError={errorMessage}
                    >
                        {children}
                    </Input>
                )
                break
        }
        return jsx
    }

    return (
        <div
            style={style}
            className={`rds-form-item rds-full-block ${size || ''} ${
                className || ''
            } ${isCheckbox ? 'rds-flex' : ''}`}
            onMouseOver={() => setActive(true)}
            onMouseLeave={() => !focusing && setActive(false)}
            onFocus={() => setFocusing(true)}
            onBlur={() => setFocusing(false)}
        >
            {isCheckbox ? (
                <Checkbox
                    id={id}
                    label={label}
                    onClick={handleCheckboxClick}
                    value={value}
                    disabled={disabled}
                    tooltip={tooltip}
                />
            ) : null}
            {isLabelled ? (
                <div className='rds-form-item_label-wrapper rds-flex rds-align-center'>
                    {icon ? (
                        <Icon
                            className={`rds-form-item_icon ${
                                active ? 'active' : ''
                            }`}
                            icon={icon}
                            width={13}
                        />
                    ) : null}
                    <div className='rds-form-item_label rds-full-block rds-relative rds-flex rds-align-center rds-justify-between'>
                        <Label
                            htmlFor={id}
                            onClick={
                                isCheckbox && !disabled
                                    ? handleCheckboxClick
                                    : null
                            }
                        >
                            {label}
                            {required ? (
                                <span
                                    title='Required'
                                    className='rds-form-item_label-required rds-m_left__sm'
                                    aria-label='required'
                                >
                                    *
                                </span>
                            ) : null}
                        </Label>
                        {tooltip ? (
                            <span className='rds-form-item_tooltip rds-relative rds-m_left__sm'>
                                <Icon
                                    className='rds-form-item_tooltip-icon'
                                    type='Info'
                                    width={4}
                                />
                                <StyledFormItemTooltipInner
                                    themeContext={themeContext}
                                    className='rds-form-item_tooltip-inner rds-absolute rds-m_right__md rds-p_left__sm rds-p_right__sm'
                                    id={tooltipId}
                                >
                                    {tooltip}
                                </StyledFormItemTooltipInner>
                            </span>
                        ) : null}
                        {showSelectedOptionsCount ? (
                            <Label className='rds-form-item_label-selection-count rds-text-align_right'>
                                {`(${value?.length || 0}/${max})`}
                            </Label>
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div className={isLabelled ? 'rds-m_top__sm' : ''}>
                {children || getFormItemType()}
            </div>
            {errorMessage ? (
                <Typography
                    type={TypographyTypes.P}
                    variant={ThemeVariants.ERROR}
                >
                    {errorMessage}
                </Typography>
            ) : null}
        </div>
    )
}

export default FormItem
