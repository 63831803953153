import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
    ThemeVariants,
    HeaderSizes,
    TypographyTypes,
    ThemesNames,
} from '../../../constants'
import { DEFAULT_CONFIG } from '../../../constants/styledComponents'
import {
    backgroundRule,
    borderRadiusRule,
    borderRule,
    borderWidthValue,
} from '../../../constants/cssRules'
import Card from '../../Card'
import Tag from '../../Tag'
import Header from '../../Header'
import Block from '../../Block'
import ComponentError from '../../States/ComponentError'
import Typography from '../../Typography'
import Icon from '../../Icon'

const SelectOption = ({ value, isActive, onSelect, cardsUI, isLastOne }) => {
    const themeContext = React.useContext(ThemeContext)
    if (cardsUI) {
        return (
            <Card
                className={`rds-full-block ${
                    isLastOne ? '' : 'rds-m_right__sm'
                }`}
                onClick={() => onSelect(value.id)}
                variant={
                    isActive && themeContext.scheme === ThemesNames.DARK
                        ? ThemeVariants.DARK
                        : null
                }
                style={
                    isActive
                        ? {
                              border: `${themeContext.borderWidth}px ${themeContext.main} solid`,
                          }
                        : undefined
                }
            >
                {isActive ? (
                    <Icon
                        className='rds-absolute'
                        icon='CheckOne'
                        theme='filled'
                        size={20}
                        variant={ThemeVariants.MAIN}
                        style={{ top: 5, right: 5 }}
                    />
                ) : null}
                <Header
                    title={value.label}
                    text={value.description}
                    size={HeaderSizes.XSMALL}
                />
            </Card>
        )
    }
    return (
        <Tag
            className={isLastOne ? '' : 'rds-m_right__sm'}
            variant={isActive ? ThemeVariants.DARK : ThemeVariants.TRANSPARENT}
            onClick={() => onSelect(value.id)}
        >
            {value.label}
        </Tag>
    )
}

const StyledDropdown = styled.div.withConfig(DEFAULT_CONFIG)`
    ${props => `
    position: relative;
    user-select: none;
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    ${borderRadiusRule(props)}
    ${
        props.isPanelVisible
            ? `
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    `
            : ''
    }
    ${backgroundRule({
        ...props,
        variant: props.disabled ? ThemeVariants.GRAY : props.variant,
    })}
    ${borderRule(props, props.isPanelVisible)}
    transition: 0.2s all;

    &:hover {
        ${props.disabled ? '' : borderRule(props, true)}
    }`}
`

const StyledDropdownPanel = styled.div.withConfig(DEFAULT_CONFIG)`
    position: absolute;
    top: 100%;
    left: -${props => borderWidthValue(props)};
    width: calc(
        100% + ${props => borderWidthValue(props)} +
            ${props => borderWidthValue(props)}
    );
    z-index: 1;
    transition: 0.2s transform;
    ${props => `visibility: ${props.visible ? 'visible' : 'hidden'};`}
    ${props => `transform: translateY(${props.visible ? 0 : '-10px'});`}
    ${props => `opacity: ${props.visible ? 1 : 0.5};`}

    & .rds-card {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        padding: var(--rds-spacingSmall);
        ${props => borderRule(props, props.visible)}
        border-top: initial;
    }
`

const StyledDropdownPanelOption = styled.div.withConfig(DEFAULT_CONFIG)`
    cursor: pointer;
    position: relative;
    & .rds-form-item_select_option-label span,
    & .rds-form-item_select_option-label b {
        position: relative;
        z-index: 1;
    }
    & .rds-form-item_select_option-label:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${props => borderRadiusRule(props)}
        background-color: ${props =>
            props.isActive ? props.themeContext.main : 'transparent'};
    }
    &:hover
        .rds-form-item_select_option-label:not(
            .rds-form-item_select_option-label_active
        ):before {
        background-color: var(
            --rds-${props =>
                    props.themeContext.scheme === ThemesNames.LIGHT
                        ? 'colorGrayLight'
                        : 'colorGrayDark'}
        );
    }
`

const Dropdown = ({
    className,
    values,
    onSelect,
    value,
    disabled,
    placeholder,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const [isPanelVisible, setIsPanelVisible] = React.useState(false)
    const toggleDropdownPanel = () => setIsPanelVisible(!isPanelVisible)
    const current = values.find(option => option.id === value)
    return (
        <StyledDropdown
            className={`rds-form-item_select ${
                disabled ? 'rds-form-item_disabled' : ''
            } ${className || ''}`}
            onClick={disabled ? undefined : toggleDropdownPanel}
            themeContext={themeContext}
            disabled={disabled}
            isPanelVisible={isPanelVisible}
        >
            <Icon
                icon='Down'
                className='rds-form-item_select_icon'
                style={{
                    transform: `translateY(-50%) rotate(${
                        isPanelVisible ? 180 : 0
                    }deg)`,
                }}
                width={10}
            />
            <Typography type={TypographyTypes.P}>
                {current ? current.label : placeholder || '-'}
            </Typography>
            <StyledDropdownPanel
                visible={isPanelVisible}
                themeContext={themeContext}
            >
                <Card
                    className='rds-p_around__sm rds-m_bottom__md'
                    style={{
                        overflowY: 'auto',
                        maxHeight: 500,
                    }}
                >
                    {values.map((option, i) => {
                        const isActive = value === option.id
                        return (
                            <StyledDropdownPanelOption
                                key={i}
                                onClick={() => onSelect(option.id)}
                                isActive={isActive}
                                themeContext={themeContext}
                            >
                                <Typography
                                    className={`rds-form-item_select_option-label ${
                                        isActive
                                            ? 'rds-form-item_select_option-label_active'
                                            : ''
                                    } rds-p_top__sm rds-p_bottom__sm rds-p_left__sm rds-p_right__sm`}
                                    type={TypographyTypes.P}
                                    variant={
                                        isActive
                                            ? ThemeVariants.WHITE
                                            : undefined
                                    }
                                >
                                    {isActive ? (
                                        <b>{option.label}</b>
                                    ) : (
                                        <span>{option.label}</span>
                                    )}
                                </Typography>
                            </StyledDropdownPanelOption>
                        )
                    })}
                </Card>
            </StyledDropdownPanel>
        </StyledDropdown>
    )
}

const Select = ({
    id,
    className,
    values,
    value,
    currentValue,
    onSelect,
    cardsUI,
    placeholder,
    disabled,
    forceDropdown,
}) => {
    const [safeValue, setSafeValue] = React.useState(currentValue)

    React.useEffect(() => {
        setSafeValue(value)
    }, [value])

    if (!values) {
        return (
            <ComponentError
                componentName='Select'
                errorMessage='A "values" prop is needed'
            />
        )
    }
    if (forceDropdown || (values.length > 2 && !cardsUI)) {
        return (
            <Dropdown
                className={className}
                values={values}
                onSelect={onSelect}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
            />
        )
    }
    return (
        <Block
            roundedBorder
            bordered
            id={id}
            className={`rds-form-select ${
                cardsUI
                    ? `rds-form-select_cards rds-full-block rds-grid rds-grid_${values.length}-columns`
                    : ''
            } ${className || ''}`}
            variant={ThemeVariants.GRAY}
        >
            {values.map((value, i) => (
                <SelectOption
                    key={i}
                    value={value}
                    isActive={
                        safeValue !== null &&
                        safeValue !== undefined &&
                        safeValue.toString() === value.id.toString()
                    }
                    onSelect={onSelect}
                    cardsUI={cardsUI}
                    isLastOne={i === values.length - 1}
                />
            ))}
        </Block>
    )
}

export default Select
