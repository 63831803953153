import React from 'react'
import { Header } from 'rds'

const Donate = () => {
    return (
        <section id='donate' className='rds-m_top'>
            <Header
                title='Help us'
                text='RDS is a small project that tries to make developers and designers life easier providing plug-n-play React components'
            />
            <form
                className='rds-m_top__md'
                action='https://www.paypal.com/cgi-bin/webscr'
                method='post'
                target='_top'
                style={{ float: 'left', maxWidth: '200px' }}
            >
                <input type='hidden' name='cmd' value='_s-xclick' />
                <input
                    type='hidden'
                    name='hosted_button_id'
                    value='QA839UURWHEDN'
                />
                <input
                    type='image'
                    src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
                    border='0'
                    name='submit'
                    title='PayPal - The safer, easier way to pay online!'
                    alt='Donate with PayPal button'
                />
                <img
                    alt=''
                    border='0'
                    src='https://www.paypal.com/en_UY/i/scr/pixel.gif'
                    width='1'
                    height='1'
                />
            </form>
        </section>
    )
}

export default Donate
