import { colorValue, textDecorationValue } from '../constants/cssRules'

/**
 * Returns the default typography configuration based on the provided properties.
 *
 * @param {Object} props - The properties to configure typography.
 * @param {string} props.variant - The variant of the typography.
 * @returns {Object} The default typography configuration.
 * @returns {string} return.color - The color value for the typography.
 * @returns {string} return.textDecoration - The text decoration value for the typography.
 */
export const getTypographyDefaultConfig = props => ({
    color: colorValue(props),
    textDecoration: textDecorationValue(props.variant),
})
