import React from 'react'
import Typography from './Typography'
import Icon from './Icon'
import HeaderBreadcrumb from './HeaderBreadcrumb'
import { TypographyTypes, HeaderSizes } from '../constants'

const Header = ({
    alignCenter,
    title,
    icon,
    text,
    size,
    onClick,
    onMouseOver,
    onMouseLeave,
    children,
    variant,
    id,
    className,
    breadcrumb,
    style,
}) => {
    const getIconSize = () => {
        switch (size) {
            case HeaderSizes.XSMALL:
                return 12
            case HeaderSizes.SMALL:
                return 15
            case HeaderSizes.MEDIUM:
                return 21
            // Default value is BIG
            default:
                return 25
        }
    }

    const getIcon = () => {
        const size = getIconSize()
        const iconStyle = {
            margin: `${size / 1.9}px 10px 0 0`,
        }
        return (
            <Icon
                className='rds-header_icon'
                type={icon}
                size={size}
                style={iconStyle}
                variant={variant}
            />
        )
    }

    const getBreadcrumb = () =>
        breadcrumb ? (
            <HeaderBreadcrumb
                link={breadcrumb.link}
                value={breadcrumb.value}
                withSeparator
            />
        ) : null

    const getTitle = () => {
        const breadcrumbContent = getBreadcrumb()
        let titleSize
        switch (size) {
            case HeaderSizes.XSMALL:
                titleSize = TypographyTypes.H4
                break
            case HeaderSizes.SMALL:
                titleSize = TypographyTypes.H3
                break
            case HeaderSizes.MEDIUM:
                titleSize = TypographyTypes.H2
                break
            default:
                titleSize = TypographyTypes.H1
                break
        }
        return (
            <Typography type={titleSize} variant={variant} onClick={onClick}>
                {breadcrumbContent}
                {title}
            </Typography>
        )
    }

    const getContent = () => {
        return (
            <React.Fragment>
                {getTitle()}
                {children}
            </React.Fragment>
        )
    }

    return (
        <header
            id={id}
            className={`main-header ${alignCenter ? 'center' : ''} ${
                className || ''
            }`}
            style={style}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <div className={icon ? 'rds-flex rds-align-top' : null}>
                {icon ? getIcon() : null}
                <div className='rds-flex rds-direction-column'>
                    {getContent()}
                    {text && typeof text === 'string' ? (
                        <Typography type={TypographyTypes.P} variant={variant}>
                            {text}
                        </Typography>
                    ) : (
                        text
                    )}
                </div>
            </div>
        </header>
    )
}

export default Header
