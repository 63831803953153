import React from 'react'
import Template from '../layout/Template'
import {
    Card,
    FormItem,
    FormItemSizes,
    FormItemTypes,
    FormSection,
    HeaderSizes,
    IconTypes,
    ThemeVariants,
} from 'rds'

const OPTIONS = Object.freeze([
    {
        id: 1,
        label: 'Option 1',
        description: 'This is the description of the first option',
    },
    {
        id: 2,
        label: 'Option 2',
        description: 'This is the description of the second option',
    },
    {
        id: 3,
        label: 'Option 3',
        description: 'This is the description of the second option',
    },
])

const FormItemTemplate = () => {
    const [cardSelectValue, setCardSelectValue] = React.useState()
    const [values, setValues] = React.useState({
        select: 1,
        multiLookup: [],
        multiLookupWithAmounts: [],
    })

    const handleInputChange = event => {
        setValues({
            ...values,
            [event.target.id]: event.target.value,
        })
    }

    return (
        <Template
            hashValue='form-item'
            componentName='FormItem'
            overview={
                <>
                    <Card
                        variant={ThemeVariants.WARNING}
                        headerTitle='Work in progress'
                        headerText="This component's documentation is still in progress, some of the properties might be outdated or inaccurate."
                        headerSize={HeaderSizes.SMALL}
                        className='rds-m_bottom__md'
                    />
                    <FormSection>
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Text input'
                            placeholder='A standard text input...'
                            id='test'
                            autoFocus
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            icon={IconTypes.USER}
                            label='Text input with icon'
                            placeholder='A standard text input...'
                        />
                        <FormItem
                            type={FormItemTypes.EMAIL}
                            size={FormItemSizes.SMALL}
                            icon='Mail'
                            label='Email input'
                            autoComplete='email'
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Password input'
                            type={FormItemTypes.PASSWORD}
                            placeholder='A standard password input...'
                            autoComplete='password'
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Required input'
                            placeholder='A required text input...'
                            required
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Tooltiped input'
                            placeholder='A tooltiped text input...'
                            tooltip='This is a useful advice on what to input here'
                            id='tooltiped'
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Required tooltiped input'
                            placeholder='A required text input with tooltip...'
                            tooltip='This is a required input with tooltip'
                            required
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Disabled input'
                            placeholder='A disabled input...'
                            disabled
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='input-with-error'
                            label='Input with error'
                            placeholder='This is how an input with an error would look like'
                            type={FormItemTypes.TEXT}
                            value={values.inputWithError}
                            onChange={handleInputChange}
                            errorMessage='There is an error on this value'
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Textarea'
                            type={FormItemTypes.TEXTAREA}
                            placeholder='A standard textarea input...'
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='select'
                            label='Select'
                            values={OPTIONS}
                            type={FormItemTypes.SELECT}
                            value={values.select}
                            onChange={e =>
                                setValues({
                                    ...values,
                                    [e.target.id]: e.target.value,
                                })
                            }
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='select'
                            label='Disabled select'
                            values={OPTIONS}
                            type={FormItemTypes.SELECT}
                            disabled
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            label='Card select'
                            type={FormItemTypes.CARD_SELECT}
                            values={OPTIONS}
                            value={cardSelectValue}
                            onChange={e => setCardSelectValue(e.target.value)}
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='multiselect'
                            label='Multi-select (with 2 maximum)'
                            values={OPTIONS}
                            type={FormItemTypes.MULTI_SELECT}
                            value={values.multiselect}
                            onChange={handleInputChange}
                            max={2}
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='multiSelectWithCounter'
                            label='Multi-select (with counters)'
                            values={OPTIONS}
                            type={FormItemTypes.MULTI_SELECT}
                            value={values.multiSelectWithCounter}
                            onChange={handleInputChange}
                            withCounter
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='tagMultiSelect'
                            label='Tag multi-select'
                            values={OPTIONS}
                            type={FormItemTypes.TAG_MULTI_SELECT}
                            value={values.tagMultiSelect}
                            onChange={handleInputChange}
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='multiSelectWithCounterAndMax'
                            label='Multi-select (with 4 maximum and counters)'
                            values={OPTIONS}
                            type={FormItemTypes.MULTI_SELECT}
                            value={values.multiSelectWithCounterAndMax}
                            onChange={handleInputChange}
                            max={4}
                            withCounter
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='filesPicker'
                            label='Files picker'
                            type={FormItemTypes.FILES_PICKER}
                            onChange={handleInputChange}
                            value={values.filesPicker}
                            multiple
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='datePicker'
                            label='Date picker'
                            type={FormItemTypes.DATE_PICKER}
                            onChange={handleInputChange}
                            value={values.datePicker}
                            multiple
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='boolean'
                            label='Checkbox'
                            type={FormItemTypes.CHECKBOX}
                            value={values.boolean}
                            onChange={handleInputChange}
                            tooltip='Checkbox with a tooltip'
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='booleanDisabled'
                            label='Disabled checkbox'
                            type={FormItemTypes.CHECKBOX}
                            value={values.booleanDisabled}
                            onChange={handleInputChange}
                            disabled
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='lookup'
                            label='Lookup'
                            type={FormItemTypes.LOOKUP}
                            value={OPTIONS.filter(
                                value => value.id === values.lookup,
                            )}
                            onChange={handleInputChange}
                            onSearch={() => OPTIONS}
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='multiLookup'
                            label='Multi Lookup'
                            type={FormItemTypes.LOOKUP}
                            value={OPTIONS.filter(option =>
                                values.multiLookup.includes(option.id),
                            )}
                            onChange={handleInputChange}
                            onSearch={() => OPTIONS}
                            multiple
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='multiLookupWithAmounts'
                            label='Multi Lookup (with amounts)'
                            type={FormItemTypes.LOOKUP}
                            value={values.multiLookupWithAmounts.map(
                                selected => {
                                    const option = OPTIONS.find(
                                        option => option.id === selected.id,
                                    )
                                    return {
                                        ...option,
                                        ...selected,
                                    }
                                },
                            )}
                            onChange={handleInputChange}
                            onSearch={() =>
                                OPTIONS.map(value => ({
                                    ...value,
                                    maxAmount: 3,
                                }))
                            }
                            multiple
                            withAmounts
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='color'
                            label='Color'
                            type={FormItemTypes.COLOR}
                            value={values.color}
                            onChange={handleInputChange}
                        />
                        <FormItem
                            size={FormItemSizes.SMALL}
                            id='colorDisabled'
                            label='Color disabled'
                            type={FormItemTypes.COLOR}
                            value={values.color}
                            onChange={handleInputChange}
                            disabled
                        />
                    </FormSection>
                </>
            }
            example={`
<Form
    title='My form'
    submitLabel='Save changes'
    submitCallback={() => alert('Hi there!')}
>
    <FormSection
        title='My primary section'
        description='This is the main section of the form'
        size={FormSectionSizes.MEDIUM}
    >
        <FormItem
            icon={IconTypes.USER}
            label='My item'
            placeholder='A standard text input...'
        />
        <FormItem
            icon={IconTypes.KEY}
            label='My password item'
            type={FormItemTypes.PASSWORD}
            placeholder='A standard password input...'
        />
        <FormItem
            label='My tooltiped item'
            placeholder='A tooltiped text input...'
            tooltip='This is a useful advice on what to input here'
        />
        <FormItem
            label='My standard textarea'
            type={FormItemTypes.TEXTAREA}
            placeholder='A standard textarea input...'
        />
        <FormItem
            id='select'
            icon={IconTypes.SALE}
            label='My select'
            values={OPTIONS}
            type={FormItemTypes.SELECT}
            value={values['select']}
            onChange={e =>
                setValues({
                    ...values,
                    [e.target.id]: e.target.value,
                })
            }
        />
        <FormItem
            icon={IconTypes.SETTINGS}
            label='My card select'
            type={FormItemTypes.CARD_SELECT}
            values={OPTIONS}
            value={cardSelectValue}
            onChange={e =>
                setCardSelectValue(e.target.value)
            }
        />
        <FormItem
            icon={IconTypes.SALE}
            label='My multi select'
            values={OPTIONS}
            type={FormItemTypes.MULTI_SELECT}
            onChange={e => console.warn(e)}
        />
        <FormItem
            icon={IconTypes.PICTURE}
            label='My files picker'
            type={FormItemTypes.FILES_PICKER}
            onChange={e => console.log(e)}
            multiple
        />
        <FormItem
            icon={IconTypes.DUEDATE}
            id='date'
            label='My date picker'
            type={FormItemTypes.DATE_PICKER}
            value={values.date}
            onChange={handleInputChange}
        />
        <FormItem
            id='boolean'
            label='My checkbox'
            type={FormItemTypes.CHECKBOX}
            value={values.boolean}
            onChange={handleInputChange}
        />
        <FormItem
            id='lookup'
            label='Lookup'
            type={FormItemTypes.LOOKUP}
            value={values.booleanDisabled}
            onChange={event => {
                // ...
            }}
            onSearch={search => {
                return [
                    {
                        id: '1234abcd',
                        name: 'Result #1',
                        maxAmount: 5
                    }
                ]
            }}
            multiple={isMultiple}
            disabled={isDisabled}
            withAmounts={withAmounts}
        />
    </FormSection>
</Form>`}
            properties={[
                {
                    name: 'align',
                    type: 'STRING',
                    description: 'HTML align attribute',
                },
                {
                    name: 'currentValue',
                    type: 'ANY',
                    description:
                        'Required for CARD_SELECT, SELECT and CHECKBOX form item types',
                },
                {
                    name: 'children',
                    type: 'FUNCTION | Node',
                    description:
                        'In case you want to customize the content of the FormItem, you can use the children prop',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'label',
                    type: 'STRING',
                    description: `Label to show`,
                },
                {
                    name: 'onChange',
                    type: 'FUNCTION',
                    description: `Element on change callback`,
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description: `Callback to execute when the component is clicked`,
                },
                {
                    name: 'placeholder',
                    type: 'STRING',
                    description: `Placholder to show in the component`,
                },
                {
                    name: 'required',
                    type: 'BOOLEAN',
                    description:
                        'If true and the component is not populated, the form will throw a message when subimtting',
                },
                {
                    name: 'size',
                    type: 'FormItemSizes',
                    description:
                        'Size of the FormItem, you can use multiple sizes to display your components differently',
                },
                {
                    name: 'type',
                    type: 'FormItemTypes',
                    description:
                        'The type of the FormItem. Import the constant to use it',
                },
                {
                    name: 'values',
                    type: 'ARRAY',
                    description:
                        'Array of options to show. Supported by CARD_SELECT and SELECT form item types',
                },
                {
                    name: 'forceDropdown',
                    type: 'BOOLEAN',
                    description:
                        'If set to true, no matter the quantity of possible "values" provided, the component will be displayed as a dropdown',
                },
                {
                    name: 'value',
                    type: 'ANY',
                    description:
                        'Dynamic value for the item. Supported by all item types',
                },
                {
                    name: 'tooltip',
                    type: 'STRING',
                    description: 'Message to show when the item is focused',
                },
                {
                    name: 'imgPreview',
                    type: 'STRING',
                    description:
                        "Supported by the FILE_BROWSER item type. Use this property to show the file's image preview",
                },
                {
                    name: 'fileSelectedCallback',
                    type: 'FUNCTION',
                    description:
                        'Callback to execute when a file is selected. Supported by FILE_BROWSER form item type',
                },
                {
                    name: 'fileDeselectedCallback',
                    type: 'FUNCTION',
                    description:
                        'Callback to execute when a file is deselected. Supported by FILE_BROWSER form item type',
                },
                {
                    name: 'errorMessage',
                    type: 'STRING',
                    description:
                        'In case you want to customize the error handling, do your conditionals and pass a String message yo this property. It will display a red alert below the component',
                },
                {
                    name: 'minLength',
                    type: 'NUMBER',
                    description: 'HTML minLength attribute',
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'HTML class attribute',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS style Object',
                },
                {
                    name: 'min',
                    type: 'NUMBER',
                    description: 'HTML min attribute',
                },
                {
                    name: 'icon',
                    type: 'IconTypes',
                    description:
                        'Icon to display at the left of the component. Import the constant to use it',
                },
                {
                    name: 'withCounter',
                    type: 'BOOLEAN',
                    description:
                        'Whether the component supports a quantity selector for its different entries. Currently only supported by components of type FormItemTypes.MULTI_SELECT',
                },
            ]}
        />
    )
}

export default FormItemTemplate
