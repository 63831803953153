import React from 'react'
import { ThemeContext } from 'styled-components'
import {
    ThemeVariants,
    TypographyTypes,
    HeaderSizes,
    TableItemTypes,
} from '../../constants'
import * as cssRules from '../../constants/cssRules'
import Tag from '../Tag'
import Card from '../Card'
import Header from '../Header'
import Button from '../Button'
import Loading from '../States/Loading'
import ComponentError from '../States/ComponentError'
import TableItem from './TableItem'
import Label from '../Label'
import Typography from '../Typography'
import Block from '../Block'
import ErrorState from './../States/ErrorState'
import EmptyState from './../States/EmptyState'

const Table = ({
    addRowAction,
    refreshAction,
    className,
    title,
    description,
    columnNames,
    rows,
    fetching,
    emptyStateTitle,
}) => {
    const themeContext = React.useContext(ThemeContext)

    const getNestedValue = (item, fieldPath) => {
        let value
        const splitted = fieldPath.split('.')
        splitted.forEach((split, i) => {
            value = i === 0 ? item[split] : value ? value[split] : null
        })
        return value
    }

    const getRowValue = (item, columnConfig) => {
        let formattedValue
        let jsxValue

        // Get actual inner value
        if (columnConfig.fieldName.includes('.')) {
            // This means the value is nested in some object
            formattedValue = getNestedValue(item, columnConfig.fieldName)
        } else {
            // Check if it is an array
            if (
                columnConfig.fieldName &&
                Array.isArray(item[columnConfig.fieldName])
            ) {
                formattedValue = item[columnConfig.fieldName].length
            } else {
                formattedValue = item[columnConfig.fieldName]
            }
        }

        // Handle API formatting options
        columnConfig.options = columnConfig.options || {}
        const { dateValue, prefix, suffix, tagged, link, action } =
            columnConfig.options
        // Date value?
        if (dateValue) {
            formattedValue = `${new Date(
                formattedValue,
            ).toLocaleDateString()} at ${new Date(
                formattedValue,
            ).toLocaleTimeString()} 📅`
        }
        // Prefix or Suffix?
        if (prefix) {
            formattedValue = prefix + ' ' + formattedValue
        }
        if (suffix) {
            formattedValue = formattedValue + ' ' + suffix
        }
        jsxValue = formattedValue

        switch (columnConfig.type) {
            case TableItemTypes.TAG:
                jsxValue = (
                    <Tag
                        onClick={action ? () => action(item) : null}
                        variant={
                            columnConfig.options?.variant
                                ? typeof columnConfig.options.variant ===
                                  'function'
                                    ? columnConfig.options.variant(
                                          formattedValue,
                                      )
                                    : columnConfig.options.variant
                                : undefined
                        }
                    >
                        {jsxValue}
                    </Tag>
                )
                break

            case TableItemTypes.IMAGE:
                jsxValue = (
                    <img
                        onClick={
                            typeof action === 'function'
                                ? () => action(item)
                                : undefined
                        }
                        className='rds-table_img'
                        src={formattedValue}
                        alt=''
                        style={{
                            borderRadius: cssRules.borderRadiusValue({
                                themeContext,
                            }),
                        }}
                    />
                )
                break

            case TableItemTypes.LINK:
                {
                    let formattedLinkValue = item[link.recordFieldName]
                    if (link.recordFieldName.includes('.')) {
                        formattedLinkValue = getNestedValue(
                            item,
                            link.recordFieldName,
                        )
                    }
                    // TODO: Replace this with Link/A component when available
                    jsxValue = (
                        <a
                            onClick={
                                !tagged && action ? () => action(item) : null
                            }
                            href={`${link.base}${
                                link.base.substring(0, -1) === '/' ? '' : '/'
                            }${formattedLinkValue}`}
                        >
                            <Typography
                                type={TypographyTypes.P}
                                className='rds-text-variant_link'
                                variant={link.variant || ThemeVariants.MAIN}
                            >
                                <b>{formattedValue}</b>
                            </Typography>
                        </a>
                    )
                }
                break

            default:
                jsxValue = (
                    <Typography type={TypographyTypes.P}>
                        {formattedValue}
                    </Typography>
                )
                break
        }

        return jsxValue
    }

    if (!columnNames) {
        return (
            <ComponentError
                componentName='Table'
                errorMessage="The 'columnNames' prop is required"
            />
        )
    }
    if (columnNames.length > 6) {
        return (
            <ErrorState
                title='Table error'
                message="There's a maximum of 6 columns."
            />
        )
    }
    return (
        <Card className={`rds-table ${className}`}>
            {title || addRowAction || refreshAction ? (
                <div className='rds-table_header rds-flex rds-align-center rds-justify-between'>
                    {title ? (
                        <Header
                            title={title}
                            text={description}
                            size={HeaderSizes.SMALL}
                        />
                    ) : null}
                    <div className='rds-flex rds-align-center'>
                        {/* TODO: Implement a search mechanism in Tables */}
                        {/* <SearchBox className='rds-m_right__sm' /> */}
                        {addRowAction ? (
                            <a href={addRowAction.link}>
                                <Button label={addRowAction.label} />
                            </a>
                        ) : null}
                        {refreshAction ? (
                            <Button icon='Refresh' onClick={refreshAction} />
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div
                className={`rds-table_column-headers rds-full-block rds-m_top__md rds-m_left__md rds-m_right__md rds-p_left__sm rds-p_right__sm ${
                    title ? '' : ''
                } rds-grid rds-table_grid_${columnNames.length}-column${
                    columnNames.length > 1 ? 's' : ''
                }`}
            >
                {columnNames.map((columnName, i) => (
                    <div key={i}>
                        <Label>{columnName.label}</Label>
                    </div>
                ))}
            </div>
            {fetching ? (
                <div className='rds-full-block rds-m_top__md'>
                    <Loading tag='Fetching data...' />
                </div>
            ) : (
                <Block
                    variant={ThemeVariants.GRAY}
                    className='rds-full-block rds-m_top__sm'
                >
                    <Card className='rds-full-block rds-table_content rds-p_around__md'>
                        {rows.length ? (
                            rows.map((item, i) => (
                                <TableItem
                                    key={i}
                                    noLine={i === rows.length - 1}
                                    className={`rds-grid rds-table_grid_${columnNames.length}-columns rds-flex rds-align-top`}
                                >
                                    {columnNames.map((columnName, i) => (
                                        <div key={i}>
                                            {getRowValue(item, columnName)}
                                        </div>
                                    ))}
                                </TableItem>
                            ))
                        ) : (
                            <EmptyState
                                title={emptyStateTitle || 'Nothing here'}
                            />
                        )}
                    </Card>
                </Block>
            )}
        </Card>
    )
}

export default Table
