import React from 'react'
import Template from '../layout/Template'
import {
    Header,
    Button,
    ButtonVariants,
    HeaderSizes,
    TableItem,
    IconTypes,
} from 'rds'

const ButtonTemplate = () => {
    return (
        <Template
            hashValue='buttons'
            componentName='Button'
            overview={
                <div>
                    <section>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Main'
                                    text='Meant to be the main action in the current page. Use it for guiding the user through the flow. We suggest using only one per section.'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='Main'
                                    variant={ButtonVariants.MAIN}
                                />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Secondary'
                                    text="This variant is meant for every action that's not crucial for the system/flow"
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button label='Secondary' />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Danger'
                                    text='Use it for actions that are permanent and cannot be undone.'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='Danger'
                                    variant={ButtonVariants.DANGER}
                                />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Danger secondary'
                                    text='Use it for actions that mean a crucial change in the app/system'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='Danger secondary'
                                    variant={ButtonVariants.DANGER_SECONDARY}
                                />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Dark'
                                    text='Use it for secondary actions or high contrast'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='Dark'
                                    variant={ButtonVariants.DARK}
                                />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Black'
                                    text='Use it for high contrast'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='Black'
                                    variant={ButtonVariants.BLACK}
                                />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Transparent'
                                    text='Use it for actions that can be easily omitted'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='Transparent'
                                    variant={ButtonVariants.TRANSPARENT}
                                />
                            </div>
                        </TableItem>
                        <TableItem>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='White'
                                    text='Use it for high contrast'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button
                                    label='White'
                                    variant={ButtonVariants.WHITE}
                                />
                            </div>
                        </TableItem>
                        <TableItem noLine>
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Header
                                    title='Disabled'
                                    text='Use it for blocked actions'
                                    size={HeaderSizes.XSMALL}
                                />
                                <Button label='Disabled' disabled />
                            </div>
                        </TableItem>
                    </section>
                    <section className='rds-m_top'>
                        <Header
                            title='Icon Variants'
                            text="Use icons to give more context to your buttons, but don't over-use them, they're meant to be in main actions or dangerous actions buttons"
                            size={HeaderSizes.MEDIUM}
                        />
                        <div className='rds-m_top__md'>
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Add new item'
                                icon={IconTypes.ADD}
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Remove item'
                                variant={ButtonVariants.DANGER_SECONDARY}
                                icon={IconTypes.BIN}
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Edit properties'
                                variant={ButtonVariants.MAIN}
                                icon={IconTypes.EDIT}
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Delete project'
                                variant={ButtonVariants.DANGER}
                                icon={IconTypes.WARNING}
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Complete'
                                icon={IconTypes.SUCCESS}
                                disabled
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                icon={IconTypes.PICTURE}
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Change password'
                                variant={ButtonVariants.TRANSPARENT}
                                icon={IconTypes.KEY}
                            />
                            <Button
                                className='rds-m_right__sm rds-m_bottom__sm'
                                label='Add to favorites'
                                variant={ButtonVariants.WHITE}
                                icon={IconTypes.HEART}
                            />
                        </div>
                    </section>
                </div>
            }
            example={`
<Button
    variant={ButtonVariants.<VARIANT>}
    onClick={Function}
    style={Object}
    className={String}
    label={String}
    filled={Boolean}
    cardHoverOnly={Boolean}
    icon={IconTypes.<ICON>}
    disabled={Boolean}
/>`}
            properties={[
                {
                    name: 'label',
                    type: 'STRING',
                    description:
                        "Label to show in the button, if not provided, an 'icon' prop value will be needed",
                },
                {
                    name: 'variant',
                    type: 'ButtonVariants',
                    description: "import { ButtonVariants } from 'rds'",
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description: 'Callback to execute when the Tag is clicked',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS object containing styles customizations',
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class/es you want to add',
                },
                {
                    name: 'filled',
                    type: 'BOOLEAN',
                    description:
                        "Handles the state of the Button's Icon, e.g.: Fav/Unfav button",
                },
                {
                    name: 'cardHoverOnly',
                    type: 'BOOLEAN',
                    description:
                        'IMPORTANT: The Button needs to be inside a Card to make this prop work prop-erly 🤓',
                },
                {
                    name: 'icon',
                    type: 'IconTypes',
                    description: 'The icon to show in the button',
                },
                {
                    name: 'disabled',
                    type: 'BOOLEAN',
                    description: 'Whether the Button is disabled or not',
                },
            ]}
        />
    )
}

export default ButtonTemplate
