import React from 'react'
import Card from '../Card'
import Header from '../Header'
import { HeaderSizes, ThemeVariants } from '../../constants'

const SPACING = 3

const PathItem = ({ items, item, onClick, value, linear }) => {
    const [isHovering, setIsHovering] = React.useState(false)

    const totalSize = items.length
    const index = items.findIndex(i => i.title === item.title)
    const activeIndex = items.findIndex(i => i.title === value)
    const isActive = activeIndex === index
    const individualSize = 100 / totalSize
    const isFirstItem = index === 0
    const isLastItem = index === totalSize - 1
    const isDisabled = linear && activeIndex >= index

    return (
        <Card
            onClick={() => {
                if (!isDisabled && typeof onClick === 'function') {
                    onClick()
                }
            }}
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
                borderTopRightRadius: isLastItem ? undefined : 60,
                borderBottomRightRadius: isLastItem ? undefined : 60,
                paddingLeft: isFirstItem ? undefined : '5%',
                paddingTop: 15,
                paddingBottom: 15,
                marginRight: `-${SPACING}%`,
                zIndex: totalSize - index,
                width: `${(isFirstItem ? 0 : SPACING) + individualSize}%`,
                cursor: isDisabled ? 'not-allowed' : undefined,
            }}
            variant={
                isDisabled || isActive
                    ? ThemeVariants.DARK
                    : isHovering
                      ? ThemeVariants.MAIN
                      : undefined
            }
            className='rds-path_item rds-relative'
        >
            <Header
                title={item.title}
                text={item.text}
                size={HeaderSizes.SMALL}
                variant={
                    isActive || isDisabled || isHovering
                        ? ThemeVariants.WHITE
                        : undefined
                }
            />
        </Card>
    )
}

export default PathItem
