import React from 'react'
import styled from 'styled-components'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import PathItem from './PathItem'

const StyledPath = styled.div.withConfig(DEFAULT_CONFIG)`
    ${props => `
    ${
        props.disabled
            ? `
        opacity: 0.5;
        pointer-events: none;
    `
            : ''
    }
`}
`

const Path = props => {
    const handlePathItemClick = item => {
        if (typeof onChange === 'function') {
            onChange(item.title)
        }
    }
    const { items, className, onChange, value, linear } = props
    return (
        <StyledPath
            {...props}
            className={`rds-path rds-flex ${className || ''}`}
        >
            {items.map(item => (
                <PathItem
                    items={items}
                    item={item}
                    value={value}
                    key={item.title}
                    onClick={() => handlePathItemClick(item)}
                    linear={linear}
                />
            ))}
        </StyledPath>
    )
}

export default Path
