import React from 'react'
import Template from '../layout/Template'
import { CodeBlock, Typography, TypographyTypes } from 'rds'
import { Link } from 'react-router-dom'

const CardTemplate = () => {
    return (
        <Template
            hashValue='code-block'
            componentName='CodeBlock'
            overview={
                <div>
                    <Typography type={TypographyTypes.H4}>
                        Default (jsx)
                    </Typography>
                    <CodeBlock>
                        {`<MyComponent>
    This is a test
</MyComponent>`}
                    </CodeBlock>
                    <Typography
                        type={TypographyTypes.H4}
                        className='rds-m_top__sm'
                    >
                        JavaScript (javascript)
                    </Typography>
                    <CodeBlock language='javascript'>
                        {`const myFunction = () => {
    // ...
}`}
                    </CodeBlock>
                </div>
            }
            example={`
<CodeBlock>
    {''}
</CodeBlock>`}
            properties={[
                {
                    name: 'id',
                    type: 'string',
                    description: 'The id of the code block.',
                },
                {
                    name: 'className',
                    type: 'string',
                    description: 'Additional classes to add to the code block.',
                },
                {
                    name: 'style',
                    type: 'object',
                    description: 'Additional styles to add to the code block.',
                },
                {
                    name: 'copyLabel',
                    type: 'string',
                    description: 'The label for the copy button.',
                },
                {
                    name: 'language',
                    type: 'string',
                    description: (
                        <>
                            The language of the code block. Defaults to
                            &apos;jsx&apos;.
                            <Link
                                to='https://github.com/rajinwonderland/react-code-blocks/blob/master/LANGUAGES.md'
                                target='_blank'
                            >
                                See all supported languages.
                            </Link>
                        </>
                    ),
                },
            ]}
        />
    )
}

export default CardTemplate
