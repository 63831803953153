import React from 'react'
import { ThemeVariants, ThemesNames } from '../constants'
import { ThemeContext } from 'styled-components'
import {
    COLOR_ERROR,
    COLOR_GREEN,
    COLOR_GREEN_TRANSPARENT,
    COLOR_PURPLE,
    COLOR_PURPLE_TRANSPARENT,
    COLOR_WARNING,
    COLOR_WHITE,
    blackValue,
    variantBackgroundValue,
} from '../constants/cssRules'
import Icon, { ALL_ICON_KEYS } from '@icon-park/react/es/all'

const IconWrapper = props => {
    const { type = 'People', variant, theme = 'outline' } = props
    const themeContext = React.useContext(ThemeContext)
    const [fill, setFill] = React.useState([])

    React.useEffect(() => {
        let pallete
        const neutralColor =
            themeContext.scheme === ThemesNames.DARK
                ? COLOR_WHITE
                : blackValue(themeContext)
        switch (variant) {
            case ThemeVariants.MAIN:
                pallete = [
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.MAIN,
                    }),
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.SECONDARY_LIGHT,
                    }),
                ]
                break

            case ThemeVariants.SECONDARY:
                pallete = [
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.SECONDARY,
                    }),
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.SECONDARY_LIGHT,
                    }),
                ]
                break

            case ThemeVariants.GRAY:
                pallete = [
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.GRAY,
                    }),
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.WHITE,
                    }),
                ]
                break

            case ThemeVariants.WHITE:
                pallete = [
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.WHITE,
                    }),
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.GRAY,
                    }),
                ]
                break

            case ThemeVariants.ERROR:
                pallete = [
                    COLOR_ERROR,
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.ERROR,
                    }),
                ]
                break

            case ThemeVariants.WARNING:
                pallete = [
                    COLOR_WARNING,
                    variantBackgroundValue({
                        themeContext,
                        variant: ThemeVariants.WARNING,
                    }),
                ]
                break

            case ThemeVariants.GREEN:
                pallete = [COLOR_GREEN, COLOR_GREEN_TRANSPARENT]
                break

            case ThemeVariants.PURPLE:
                pallete = [COLOR_PURPLE, COLOR_PURPLE_TRANSPARENT]
                break

            case ThemeVariants.DARK:
                pallete = [blackValue(themeContext), blackValue(themeContext)]
                break

            default:
                pallete = [neutralColor, neutralColor]
                break
        }
        setFill(pallete)
    }, [themeContext, variant])

    if (!ALL_ICON_KEYS.includes(type)) {
        console.error('Received invalid icon type:', type)
        return null
    }
    return (
        <Icon
            {...props}
            className={`rds-icon ${props.className || ''}`}
            type={props.type || props.icon}
            theme={theme}
            fill={fill}
        />
    )
}

export default IconWrapper
