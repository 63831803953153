import React from 'react'
import { Header, Table, HeaderSizes } from 'rds'

const CSS = () => {
    return (
        <section id='css' className='rds-m_top'>
            <Header title='CSS' />
            <div className='rds-m_top__md'>
                <Header
                    title='Reference'
                    text='In case you want to give some customization to the RDS components, or create your own following the design guidelines'
                    size={HeaderSizes.MEDIUM}
                />
                <Table
                    title='Margins'
                    className='rds-m_top__sm'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-m_around',
                            description: 'Big margin',
                        },
                        {
                            name: 'rds-m_around__md',
                            description: 'Medium margin',
                        },
                        {
                            name: 'rds-m_around__sm',
                            description: 'Small margin',
                        },
                        {
                            name: 'rds-m_top',
                            description: 'Big margin-top',
                        },
                        {
                            name: 'rds-m_top__md',
                            description: 'Medium margin-top',
                        },
                        {
                            name: 'rds-m_top__sm',
                            description: 'Small margin-top',
                        },
                        {
                            name: 'rds-m_bottom',
                            description: 'Big margin-bottom',
                        },
                        {
                            name: 'rds-m_bottom__md',
                            description: 'Medium margin-bottom',
                        },
                        {
                            name: 'rds-m_bottom__sm',
                            description: 'Small margin-bottom',
                        },
                        {
                            name: 'rds-m_right',
                            description: 'Big margin-right',
                        },
                        {
                            name: 'rds-m_right__md',
                            description: 'Medium margin-right',
                        },
                        {
                            name: 'rds-m_right__sm',
                            description: 'Small margin-right',
                        },
                        {
                            name: 'rds-m_left',
                            description: 'Big margin-left',
                        },
                        {
                            name: 'rds-m_left__md',
                            description: 'Medium margin-left',
                        },
                        {
                            name: 'rds-m_left__sm',
                            description: 'Small margin-left',
                        },
                    ]}
                />
                <Table
                    title='Paddings'
                    className='rds-m_top__sm'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-p_around',
                            description: 'Big padding',
                        },
                        {
                            name: 'rds-p_around__md',
                            description: 'Medium padding',
                        },
                        {
                            name: 'rds-p_around__sm',
                            description: 'Small padding',
                        },
                        {
                            name: 'rds-p_top',
                            description: 'Big padding-top',
                        },
                        {
                            name: 'rds-p_top__md',
                            description: 'Medium padding-top',
                        },
                        {
                            name: 'rds-p_top__sm',
                            description: 'Small padding-top',
                        },
                        {
                            name: 'rds-p_bottom',
                            description: 'Big padding-bottom',
                        },
                        {
                            name: 'rds-p_bottom__md',
                            description: 'Medium padding-bottom',
                        },
                        {
                            name: 'rds-p_bottom__sm',
                            description: 'Small padding-bottom',
                        },
                        {
                            name: 'rds-p_right',
                            description: 'Big padding-right',
                        },
                        {
                            name: 'rds-p_right__md',
                            description: 'Medium padding-right',
                        },
                        {
                            name: 'rds-p_right__sm',
                            description: 'Small padding-right',
                        },
                        {
                            name: 'rds-p_left',
                            description: 'Big padding-left',
                        },
                        {
                            name: 'rds-p_left__md',
                            description: 'Medium padding-left',
                        },
                        {
                            name: 'rds-p_left__sm',
                            description: 'Small padding-left',
                        },
                    ]}
                />
                <Table
                    title='Flex'
                    className='rds-m_top__md'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-flex',
                            description: 'display: flex',
                        },
                        {
                            name: 'rds-flex.rds-align-center',
                            description: 'align-items: center',
                        },
                        {
                            name: 'rds-flex.rds-align-top',
                            description: 'align-items: top',
                        },
                        {
                            name: 'rds-flex.rds-align-bottom',
                            description: 'align-items: bottom',
                        },
                        {
                            name: 'rds-flex.rds-justify-center',
                            description: 'justify-content: center',
                        },
                        {
                            name: 'rds-flex.rds-justify-between',
                            description: 'justify-content: space-between',
                        },
                        {
                            name: 'rds-flex.rds-justify-evenly',
                            description: 'justify-content: space-evenly',
                        },
                        {
                            name: 'rds-flex.rds-direction-column',
                            description: 'flex-direction: column',
                        },
                    ]}
                />
                <Table
                    title='Sizing'
                    className='rds-m_top__md'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-full-block',
                            description:
                                'Makes the element a full-width, left-positioned block',
                        },
                        {
                            name: 'rds-height_fill-available',
                            description:
                                'Makes the element fill the available vertical space',
                        },
                        {
                            name: 'rds-width_fill-available',
                            description:
                                'Makes the element fill the available horizontal space',
                        },
                    ]}
                />
                <Table
                    title='Wrapping'
                    className='rds-m_top__md'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-nowrap',
                            description: 'Disables wrapping',
                        },
                    ]}
                />
                <Table
                    title='Positioning'
                    className='rds-m_top__md'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-relative',
                            description: 'Makes the element a relative',
                        },
                        {
                            name: 'rds-absolute',
                            description: 'Makes the element absolute',
                        },
                        {
                            name: 'rds-screen-centered',
                            description:
                                'Screen centers the element in relation to the closest relative',
                        },
                    ]}
                />
                <Table
                    title='Overflow'
                    className='rds-m_top__md'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-overflow_auto',
                            description: 'Adds auto overflow',
                        },
                        {
                            name: 'rds-overflow_hidden',
                            description: 'Hides the elements overflow',
                        },
                        {
                            name: 'rds-overflow_visible',
                            description: 'Makes the elements overflow visible',
                        },
                    ]}
                />
                <Table
                    title='Grids'
                    className='rds-m_top__md'
                    columnNames={[
                        {
                            label: 'Name',
                            fieldName: 'name',
                            options: {
                                tagged: true,
                            },
                        },
                        {
                            label: 'Description',
                            fieldName: 'description',
                        },
                    ]}
                    rows={[
                        {
                            name: 'rds-grid',
                            description: 'Grid parent',
                        },
                        {
                            name: 'rds-grid.rds-grid_2-columns',
                            description: '2 columns',
                        },
                        {
                            name: 'rds-grid.rds-grid_3-columns',
                            description: '3 columns',
                        },
                        {
                            name: 'rds-grid.rds-grid_4-columns',
                            description: '4 columns',
                        },
                        {
                            name: 'rds-grid.rds-grid_5-columns',
                            description: '5 columns',
                        },
                        {
                            name: 'rds-grid_2-columns_item',
                            description:
                                'Makes an element expand to 2 columns in width',
                        },
                        {
                            name: 'rds-grid_3-columns_item',
                            description:
                                'Makes an element expand to 3 columns in width',
                        },
                        {
                            name: 'rds-grid_4-columns_item',
                            description:
                                'Makes an element expand to 4 columns in width',
                        },
                        {
                            name: 'rds-grid_5-columns_item',
                            description:
                                'Makes an element expand to 5 columns in width',
                        },
                        {
                            name: 'rds-grid_6-columns_item',
                            description:
                                'Makes an element expand to 6 columns in width',
                        },
                        {
                            name: 'rds-grid_2-rows_item',
                            description:
                                'Makes an element expand to 2 rows in height',
                        },
                        {
                            name: 'rds-grid_3-rows_item',
                            description:
                                'Makes an element expand to 3 rows in height',
                        },
                        {
                            name: 'rds-grid_4-rows_item',
                            description:
                                'Makes an element expand to 4 rows in height',
                        },
                        {
                            name: 'rds-grid_5-rows_item',
                            description:
                                'Makes an element expand to 5 rows in height',
                        },
                        {
                            name: 'rds-grid_6-rows_item',
                            description:
                                'Makes an element expand to 6 rows in height',
                        },
                    ]}
                />
            </div>
        </section>
    )
}

export default CSS
