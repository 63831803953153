import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemesNames, ThemeVariants } from '../constants'
import {
    blackValue,
    borderValue,
    variantBackgroundValue,
    borderRadiusValue,
} from '../constants/cssRules'
import { DEFAULT_CONFIG } from '../constants/styledComponents'

const getBackgroundColor = props => {
    if (props.disabled) {
        return `var(--rds-colorDisabled${
            props.themeContext.scheme === ThemesNames.LIGHT ? 'Light' : 'Dark'
        })`
    }
    switch (props.variant) {
        case ThemeVariants.MAIN:
            return props.themeContext.main

        case ThemeVariants.SECONDARY:
            return props.themeContext.secondary

        case ThemeVariants.GRAY:
            return variantBackgroundValue(props)

        case ThemeVariants.DARK:
            return blackValue(props.themeContext)

        case ThemeVariants.GLOSSY:
            return variantBackgroundValue(props)

        default:
            return props.themeContext.scheme === ThemesNames.DARK
                ? '#141414'
                : 'white'
    }
}

const StyledBlock = styled.div.withConfig(DEFAULT_CONFIG)(props => ({
    background: getBackgroundColor(props),
    borderRadius: props.roundedBorder ? borderRadiusValue(props) : null,
    border: props.bordered ? borderValue(props) : null,
}))

const Block = props => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledBlock {...{ ...props, themeContext }}>
            {props.children}
        </StyledBlock>
    )
}

export default Block
