import React from 'react'
import Block from '../../Block'
import Typography from '../../Typography'
import Checkbox from '../Checkbox'

const MultyEntryAdditionalInput = ({
    disabled = false,
    quantity = 0,
    onChange,
    step = 1,
    isMaxReached = false,
}) => {
    const isLessDisabled = disabled || quantity === 0
    const isMoreDisabled = disabled || isMaxReached
    return (
        <Block
            className='rds-flex rds-align-center'
            style={{
                padding: 4,
                userSelect: 'none',
                opacity: disabled ? 0.5 : 1,
            }}
            bordered
            roundedBorder
        >
            <Block
                onClick={
                    isLessDisabled ? null : () => onChange(quantity - step)
                }
                style={{
                    cursor: isLessDisabled ? 'not-allowed' : 'pointer',
                    padding: '0 7px',
                    lineHeight: 1,
                    opacity: isLessDisabled ? 0.5 : 1,
                }}
                bordered
                roundedBorder
            >
                <Typography>-</Typography>
            </Block>
            <Typography className='rds-p_left__sm rds-p_right__sm'>
                {quantity}
            </Typography>
            <Block
                onClick={
                    isMoreDisabled ? null : () => onChange(quantity + step)
                }
                style={{
                    cursor: isMoreDisabled ? 'not-allowed' : 'pointer',
                    padding: '0 7px',
                    lineHeight: 1,
                    opacity: isMoreDisabled ? 0.5 : 1,
                }}
                bordered
                roundedBorder
            >
                <Typography>+</Typography>
            </Block>
        </Block>
    )
}

const MultiSelectWithCounterEntry = ({
    disabled,
    isMaxReached,
    label,
    onChange,
    quantity,
    value,
}) => {
    return (
        <div
            className='rds-flex rds-align-center rds-justify-between'
            style={{ marginBottom: 4 }}
        >
            <Typography>{label}</Typography>
            <MultyEntryAdditionalInput
                quantity={quantity}
                onChange={quantity => onChange(value, quantity)}
                isMaxReached={isMaxReached}
                disabled={disabled}
            />
        </div>
    )
}

const MultiSelectWithCounter = ({
    disabled = false,
    max,
    onSelect,
    values = [],
    value = [],
}) => {
    const handleEntryChange = (key, quantity) => {
        let currentMergedValues = [...value]
        // Remove key's appearences
        currentMergedValues = currentMergedValues.filter(
            currentKey => currentKey !== key,
        )
        for (let index = 0; index < quantity; index++) {
            currentMergedValues.push(key)
        }
        onSelect(currentMergedValues)
    }

    const selectedCount = value.length
    const isMaxReached = selectedCount === max
    return (
        <div className='rds-m_top__sm'>
            {values.map((option, index) => (
                <MultiSelectWithCounterEntry
                    key={index}
                    label={option.label}
                    value={option.id}
                    quantity={value.filter(key => key === option.id).length}
                    onChange={handleEntryChange}
                    isMaxReached={isMaxReached}
                    disabled={disabled}
                />
            ))}
        </div>
    )
}

const MultiSelectWithoutCounter = ({
    values,
    onSelect,
    value = [],
    max,
    disabled,
}) => {
    const [maxOptionsSelected, setMaxOptionsSelected] = React.useState()

    React.useEffect(() => {
        setMaxOptionsSelected(max && max === value.length)
    }, [max, value])

    const handleClick = valueId => {
        let newValues = []
        if (Array.isArray(value)) {
            if (!value.includes(valueId)) {
                // Add the value
                newValues = [...value, valueId]
            } else {
                // Remove the value
                newValues = value.filter(a => a !== valueId)
            }
        }
        onSelect(newValues)
    }

    return values.map(option => {
        const isActive = Array.isArray(value) && value.includes(option.id)
        // If maximum values are selected, the disable the rest
        const isDisabled = (!isActive && maxOptionsSelected) || disabled
        return (
            <div
                className='rds-flex rds-m_bottom__sm'
                onClick={() => (isDisabled ? null : handleClick(option.id))}
                key={option.id}
            >
                <Checkbox disabled={isDisabled} value={isActive} />
                <Typography>{option.label}</Typography>
            </div>
        )
    })
}

const MultiSelect = ({
    id,
    values,
    onSelect,
    value = [],
    max,
    disabled,
    withCounter = false,
}) => {
    return (
        <div id={id}>
            {withCounter ? (
                <MultiSelectWithCounter
                    values={values}
                    onSelect={onSelect}
                    value={value}
                    max={max}
                    disabled={disabled}
                />
            ) : (
                <MultiSelectWithoutCounter
                    values={values}
                    onSelect={onSelect}
                    value={value}
                    max={max}
                    disabled={disabled}
                />
            )}
        </div>
    )
}

export default MultiSelect
