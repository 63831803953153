import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemeVariants, TypographyTypes } from '../../constants'
import * as cssRules from '../../constants/cssRules'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import Typography from '../Typography'

const StyledCounter = styled.span.withConfig(DEFAULT_CONFIG)(props => ({
    minWidth: 50,
    textAlign: 'center',
    backgroundColor: cssRules.variantBackgroundValue({
        ...props,
        variant: ThemeVariants.GRAY,
    }),
    borderRadius: cssRules.borderRadiusValue(props),
}))

const StyledCounterController = styled.span.withConfig(DEFAULT_CONFIG)`
    padding: 5px;
    font-size: 0.8rem;
    width: 20px;
    height: 20px;
    line-height: 10px;
    cursor: pointer;
    display: block;
    text-align: center;
    user-select: none;
    transition:
        0.2s opacity,
        0.4s transform;
    color: ${cssRules.COLOR_WHITE};
    background-color: ${props => cssRules.blackValue(props.themeContext)};
    ${props => cssRules.borderRadiusRule(props)};
    ${props =>
        props.disabled
            ? `
        opacity: 0.3;
        cursor: not-allowed;
    `
            : ''}
    ${props =>
        props.isClicked
            ? `
        transform: scale(1.1);
    `
            : ''}
`

const Counter = ({
    steps,
    minValue,
    maxValue,
    onChange,
    keyName,
    refreshCounter,
    value,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const [anyMore, setAnyMore] = React.useState(false)
    const [anyLess, setAnyLess] = React.useState(false)
    const [incrementClickedClass, setIncrementClickedClass] =
        React.useState(false)
    const [decrementClickedClass, setDecrementClickedClass] =
        React.useState(false)

    /**
     * Handles minimum and maximum value
     */
    React.useEffect(() => {
        if (value === minValue) {
            setAnyLess(true)
            if (value < maxValue) {
                setAnyMore(false)
            }
        } else if (
            value === maxValue &&
            maxValue !== null &&
            maxValue !== undefined
        ) {
            setAnyMore(true)
            if (value > minValue) {
                setAnyLess(false)
            }
        } else {
            setAnyLess(false)
            setAnyMore(false)
        }
    }, [minValue, maxValue, value])

    const handleRestartCounter = React.useCallback(() => {
        if (typeof onChange === 'function') {
            onChange(keyName, 0)
        }
    }, [onChange, keyName])

    React.useEffect(() => {
        if (refreshCounter === true) {
            handleRestartCounter()
        }
    }, [handleRestartCounter, refreshCounter])

    const handleControllerClick = increment => {
        let newCounter
        if (!increment) {
            newCounter = value - (steps ? steps : 1)
            setDecrementClickedClass(true)
            setTimeout(() => {
                setDecrementClickedClass(false)
            }, 200)
        } else {
            newCounter = value + (steps ? steps : 1)
            setIncrementClickedClass(true)
            setTimeout(() => {
                setIncrementClickedClass(false)
            }, 200)
        }
        if (typeof onChange === 'function') {
            onChange(keyName, newCounter)
        }
    }

    return (
        <div className='settings_counter rds-flex rds-align-center'>
            <StyledCounterController
                themeContext={themeContext}
                onClick={anyLess ? null : () => handleControllerClick(false)}
                isClicked={decrementClickedClass}
                disabled={anyLess}
            >
                -
            </StyledCounterController>
            <StyledCounter
                themeContext={themeContext}
                className='settings_count rds-p_around__sm rds-m_left__sm rds-m_right__sm'
            >
                <Typography type={TypographyTypes.P}>{value}</Typography>
            </StyledCounter>
            <StyledCounterController
                themeContext={themeContext}
                onClick={anyMore ? null : () => handleControllerClick(true)}
                isClicked={incrementClickedClass}
                disabled={anyMore}
            >
                +
            </StyledCounterController>
        </div>
    )
}

export default React.memo(Counter)
