import React from 'react'
import { Header } from 'rds'
import changelog from '../changelog'
import ChangelogItem from '../components/ChangelogItem'

const Changelog = () => {
    return (
        <section id='changelog' className='rds-m_top'>
            <Header title='Changelog' />
            <div className='rds-m_top__md'>
                {changelog.map((item, index) => (
                    <ChangelogItem key={index} item={item} />
                ))}
            </div>
        </section>
    )
}

export default Changelog
