import React from 'react'
import Template from '../layout/Template'
import { Block, ThemeVariants, Header, HeaderSizes } from 'rds'

const BlockTemplate = () => {
    return (
        <Template
            hashValue='block'
            componentName='Block'
            overview={
                <div style={{ maxWidth: '500px' }}>
                    <Block className='rds-p_around__md'>
                        <Header
                            title='Default variant'
                            text='It will use the main
                            background color'
                            size={HeaderSizes.SMALL}
                        />
                    </Block>
                    <Block
                        variant={ThemeVariants.DARK}
                        className='rds-p_around__md rds-m_top__sm'
                    >
                        <Header
                            title='Dark variant'
                            text='Make sure the content is set to white'
                            size={HeaderSizes.SMALL}
                            variant={ThemeVariants.WHITE}
                        />
                    </Block>
                    <Block
                        variant={ThemeVariants.GRAY}
                        className='rds-p_around__md rds-m_top__sm'
                    >
                        <Header
                            title='Gray variant'
                            text='Very useful for applying hierarchy'
                            size={HeaderSizes.SMALL}
                        />
                    </Block>
                    <Block
                        variant={ThemeVariants.MAIN}
                        className='rds-p_around__md rds-m_top__sm'
                    >
                        <Header
                            title='Main color variant'
                            text='For high-importance components'
                            size={HeaderSizes.SMALL}
                            variant={ThemeVariants.WHITE}
                        />
                    </Block>
                    <Block
                        variant={ThemeVariants.SECONDARY}
                        className='rds-p_around__md rds-m_top__sm'
                    >
                        <Header
                            title='Secondary color variant'
                            text='For accents'
                            size={HeaderSizes.SMALL}
                        />
                    </Block>
                    <Block
                        variant={ThemeVariants.GLOSSY}
                        className='rds-p_around__md rds-m_top__sm'
                    >
                        <Header
                            title='Glossy color variant'
                            text='A cool touch, but be careful with contrasts'
                            size={HeaderSizes.SMALL}
                        />
                    </Block>
                    <div className='rds-m_top'>
                        <Header
                            title='Border properties'
                            size={HeaderSizes.SMALL}
                        />
                        <Block className='rds-p_around__md' bordered>
                            <Header
                                title='Bordered'
                                text='Will apply a light border to the block, similar to Cards'
                                size={HeaderSizes.SMALL}
                            />
                        </Block>
                        <Block
                            variant={ThemeVariants.DARK}
                            className='rds-m_top__md rds-p_around__md rds-m_top__sm'
                            roundedBorder
                        >
                            <Header
                                title='This is the rounded borders version'
                                text='adding a padding like this one, it might look exactly like a Card'
                                size={HeaderSizes.XSMALL}
                                variant={ThemeVariants.WHITE}
                            />
                        </Block>
                    </div>
                    <div className='rds-m_top'>
                        <Header
                            title='Actionable block'
                            text='As other components, Blocks can be actionables'
                            size={HeaderSizes.SMALL}
                        />
                        <Block
                            variant={ThemeVariants.DARK}
                            className='rds-m_top__md rds-p_around__md rds-m_top__sm'
                            roundedBorder
                            onClick={() => alert('You clicked the block!')}
                        >
                            <Header
                                title='Click on me'
                                size={HeaderSizes.XSMALL}
                                variant={ThemeVariants.WHITE}
                            />
                        </Block>
                    </div>
                </div>
            }
            example={`
<Block
    variant={ThemeVariants.SECONDARY}
    bordered
    roundedBorder
>
    // Some content here...
</Block>`}
            properties={[
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class string',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS styles object',
                },
                {
                    name: 'bordered',
                    type: 'BOOLEAN',
                    description: 'Whether borders should be used or not',
                },
                {
                    name: 'roundedBorder',
                    type: 'BOOLEAN',
                    description:
                        'Whether rounded borders should be used or not',
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description:
                        'Function executed when onclick event is triggered',
                },
                {
                    name: 'variant',
                    type: 'ThemeVariants',
                    description: 'The variant of the component',
                },
            ]}
        />
    )
}

export default BlockTemplate
