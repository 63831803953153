import React from 'react'
import { Link } from 'react-router-dom'
import {
    ThemesNames,
    Logo,
    FormItem,
    FormItemTypes,
    Typography,
    TypographyTypes,
    Block,
    cssRules,
    ThemeContext,
    Icon,
    ThemeVariants,
} from 'rds'

const Head = ({ setThemeScheme, themeScheme }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <Block
            id='head'
            className='rds-full-block rds-flex rds-align-center rds-justify-between collapsed rds-p_left__md rds-p_right__md'
            style={{
                borderBottom: `${cssRules.borderWidthValue({
                    themeContext,
                })} ${cssRules.borderColorValue({ themeContext })} solid`,
            }}
        >
            <Link to='/' className='rds-flex rds-align-center'>
                <Logo />
                <Typography
                    type={TypographyTypes.H3}
                    style={{
                        fontSize: 21,
                        fontWeight: 'lighter',
                        marginTop: -3.5,
                    }}
                >
                    &nbsp;design system
                </Typography>
            </Link>
            <FormItem
                onChange={event => {
                    const scheme = event.target.value
                    setThemeScheme(scheme)
                    localStorage.setItem('rds_scheme', scheme)
                }}
                type={FormItemTypes.SELECT}
                values={[
                    {
                        id: ThemesNames.LIGHT,
                        label: (
                            <Icon
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                                type='Sun'
                                variant={
                                    themeScheme === ThemesNames.LIGHT
                                        ? ThemeVariants.WHITE
                                        : null
                                }
                            />
                        ),
                    },
                    {
                        id: ThemesNames.DARK,
                        label: (
                            <Icon
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                                type='Moon'
                                variant={
                                    themeScheme === ThemesNames.DARK
                                        ? ThemeVariants.WHITE
                                        : null
                                }
                            />
                        ),
                    },
                ]}
                value={themeScheme}
                currentValue={themeScheme}
            />
        </Block>
    )
}

export default Head
