import React from 'react'
import { ThemeContext } from 'styled-components'
import { ThemeVariants, TypographyTypes } from '../../../constants'
import * as cssRules from '../../../constants/cssRules'
import Card from './../../Card'
import Loading from './../../States/Loading'
import Typography from './../../Typography'
import LookupSelectedItem from './LookupSelectedItem'
import LookupResultItem from './LookupResultItem'
import Button from '../../Button'
import Input from '../Input'
import './Lookup.css'

const Lookup = ({
    autoFocus,
    deselectAllLabel = 'Deselect all',
    documentCustomGetter,
    disabled = false,
    id,
    inlineInsertAction,
    multiple = false,
    noResultsLabel = 'No results',
    onChange,
    onSearch,
    onFocus,
    onBlur,
    onError,
    placeholder,
    resultsLabel = 'Results',
    selectedLabel = 'Selected',
    value = [],
    withAmounts = false,
}) => {
    const timeout = React.useRef()
    const themeContext = React.useContext(ThemeContext)
    const [itemSearch, setItemSearch] = React.useState({
        isFetching: false,
        results: [],
    })
    const isSearchInputVisible =
        multiple || !value || (Array.isArray(value) && !value.length)
    const isResultsPanelVisible =
        itemSearch.isFetching ||
        itemSearch.results?.length ||
        (value?.length && multiple)

    /**
     * Simulates an HTML DOM event sending the updated selections
     */
    const handleChange = value => {
        const formattedSelections = formatSelections(value)
        onChange({
            target: {
                id,
                value: formattedSelections,
            },
        })
    }

    const handleItemSearch = event => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        const search = event.target.value
        if (!search || !search.length) {
            setItemSearch(itemSearch => ({
                ...itemSearch,
                isFetching: false,
                results: [],
                noResults: false,
            }))
            return
        }
        timeout.current = setTimeout(async () => {
            if (typeof onSearch !== 'function') {
                return
            }
            setItemSearch(itemSearch => ({
                ...itemSearch,
                isFetching: true,
            }))
            const results = await onSearch(search)
            const isResultArray = Array.isArray(results)
            setItemSearch(itemSearch => ({
                ...itemSearch,
                searchString: search,
                isFetching: false,
                results: isResultArray ? results : [],
                noResults: isResultArray && results.length === 0 ? true : false,
            }))
        }, 250)
    }

    const formatSelections = (newSelectedItems = []) => {
        if (multiple && Array.isArray(newSelectedItems)) {
            if (withAmounts) {
                const formattedSelections = []
                for (let index = 0; index < newSelectedItems.length; index++) {
                    formattedSelections.push({
                        id: newSelectedItems[index].id,
                        amount: newSelectedItems[index].amount || 1,
                    })
                }
                return formattedSelections
            } else {
                return newSelectedItems.map(selectedItem => selectedItem.id)
            }
        } else {
            return newSelectedItems.length ? newSelectedItems[0].id : null
        }
    }

    const handleSelectItem = item => {
        if (multiple) {
            const alreadyThere =
                Array.isArray(value) &&
                !!value.find(selected => selected.id === item.id)
            if (!alreadyThere) {
                // Remove item from result list
                setItemSearch(itemSearch => ({
                    ...itemSearch,
                    results: itemSearch.results.filter(
                        res => res.id !== item.id,
                    ),
                }))

                // Add to selected array
                const newSelectedItems = [...value, item]
                handleChange(newSelectedItems)
            } else {
                alert('Este item ya esta seleccionado')
            }
        } else {
            setItemSearch(itemSearch => ({
                ...itemSearch,
                results: [],
            }))
            handleChange([item])
        }
    }

    const handleDeselectItem = option => {
        if (!Array.isArray(value)) {
            handleChange(null)
            return
        }
        const deselectingIndex = value.indexOf(option)
        const newSelectedItems = value.slice()
        newSelectedItems.splice(deselectingIndex, 1)
        handleChange(newSelectedItems)
    }

    const handleDeselectAll = () => {
        handleChange([])
    }

    const handleInlineInsert = async () => {
        try {
            setItemSearch({
                ...itemSearch,
                isFetching: true,
            })
            const res = await inlineInsertAction(itemSearch.searchString)
            setItemSearch({
                ...itemSearch,
                isFetching: false,
                noResults: false,
            })
            const insertedItem = documentCustomGetter(res)
            handleSelectItem(insertedItem)
        } catch (error) {
            setItemSearch({
                ...itemSearch,
                isFetching: false,
                noResults: false,
            })
            if (typeof onError === 'function') {
                onError(
                    error.response
                        ? error.response.data.message
                        : error.toString(),
                )
            }
        }
    }

    const handleCounterChange = (keyName, newAmount) => {
        const currentItems = [...value]
        const updatedItemIndex = currentItems.findIndex(
            item => item.id === keyName,
        )
        const newItem = { ...currentItems[updatedItemIndex], amount: newAmount }
        currentItems[updatedItemIndex] = newItem
        handleChange(currentItems)
    }

    return (
        <div>
            {isSearchInputVisible ? (
                <Input
                    id={id}
                    className={`rds-item-browser_input ${
                        isResultsPanelVisible
                            ? 'rds-item-browser_input_active'
                            : ''
                    }`}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    onChange={handleItemSearch}
                    disabled={disabled}
                    autoFocus={autoFocus}
                ></Input>
            ) : null}
            {!multiple && !isSearchInputVisible ? (
                <LookupSelectedItem
                    item={value[0]}
                    onDeselect={handleDeselectItem}
                    onCounterChange={withAmounts ? handleCounterChange : null}
                    full
                />
            ) : null}
            {!itemSearch.isFetching && itemSearch.noResults ? (
                <div className='rds-full-block'>
                    {inlineInsertAction ? (
                        <Button
                            label={
                                <>
                                    Create &nbsp;
                                    <b>{itemSearch.searchString}</b>
                                </>
                            }
                            onClick={handleInlineInsert}
                        />
                    ) : (
                        <Typography
                            type={TypographyTypes.P}
                            className='rds-full-block rds-m_top__sm'
                        >
                            {noResultsLabel}
                        </Typography>
                    )}
                </div>
            ) : null}
            {isResultsPanelVisible ? (
                <Card
                    className='rds-p_around__sm'
                    style={{
                        borderTopLeftRadius: 'unset',
                        borderTopRightRadius: 'unset',
                        transform: `translateY(-${themeContext.borderWidth}px)`,
                    }}
                    variant={ThemeVariants.GRAY}
                    roundedBorder
                >
                    {itemSearch.isFetching || itemSearch.results.length ? (
                        <div
                            className='rds-item-browser_results'
                            style={
                                value?.length
                                    ? {
                                          borderBottom: `${cssRules.borderValue(
                                              {
                                                  themeContext,
                                              },
                                          )}`,
                                          paddingBottom:
                                              cssRules.getCSSVariable(
                                                  'spacingSmall',
                                              ),
                                      }
                                    : undefined
                            }
                        >
                            <Typography type={TypographyTypes.P}>
                                {resultsLabel}
                            </Typography>
                            <div className='rds-m_top__sm'>
                                {itemSearch.isFetching ? (
                                    <Loading tag='Buscando...' />
                                ) : (
                                    itemSearch.results.map(result => (
                                        <LookupResultItem
                                            key={result.id}
                                            item={result}
                                            locked={result.stock === 0}
                                            handleSelectItem={handleSelectItem}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    ) : null}
                    {multiple && value?.length ? (
                        <div
                            className={`rds-full-block ${
                                itemSearch.results?.length
                                    ? 'rds-m_top__sm'
                                    : ''
                            }`}
                        >
                            <div className='rds-flex rds-align-center rds-justify-between'>
                                <Typography type={TypographyTypes.P}>
                                    {selectedLabel}
                                </Typography>
                                {value.length > 1 ? (
                                    <div
                                        onClick={handleDeselectAll}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Typography
                                            variant={ThemeVariants.MAIN}
                                            className='rds-text-variant_link'
                                        >
                                            {deselectAllLabel}
                                        </Typography>
                                    </div>
                                ) : null}
                            </div>
                            <div className='rds-m_top__sm'>
                                {value.map((selectedProduct, index) => (
                                    <LookupSelectedItem
                                        key={selectedProduct.id}
                                        item={selectedProduct}
                                        onDeselect={handleDeselectItem}
                                        onCounterChange={
                                            withAmounts
                                                ? handleCounterChange
                                                : null
                                        }
                                        position={index}
                                        full
                                    />
                                ))}
                            </div>
                        </div>
                    ) : null}
                </Card>
            ) : null}
        </div>
    )
}

export default Lookup
