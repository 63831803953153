import React from 'react'
import { Card, CodeBlock, Header, HeaderSizes, ThemeVariants } from 'rds'

const Components = () => {
    return (
        <section id='components' className='rds-m_top'>
            <Header
                title='Components'
                text='RDS is a collection of React components that lets you build your application very quickly. Choose one of the components at the Sidebar to read the docs.'
            />
            <div className='rds-m_top__md'>
                <Header
                    title='1. Import components and constants'
                    size={HeaderSizes.SMALL}
                />
                <CodeBlock className='rds-m_top__sm'>
                    {`import { Card, Header, HeaderSizes } from 'rds'`}
                </CodeBlock>
                <Card
                    className='rds-m_top__sm'
                    headerTitle='Constants are values provided for customizing the components'
                    headerText='Behind the scenes, these are just String values that you can directly type, but we provide them in order to prevent errors.'
                    variant={ThemeVariants.WARNING}
                    headerSize={HeaderSizes.XSMALL}
                />
            </div>
            <div className='rds-m_top__md'>
                <Header
                    title='2. Start building'
                    text='You are ready to go! Create some cool components'
                    size={HeaderSizes.SMALL}
                />
                <CodeBlock className='rds-m_top__sm'>
                    {`<Tag variant={ThemeVariants.WARNING}>\n     My custom tag\n</Tag>`}
                </CodeBlock>
            </div>
        </section>
    )
}

export default Components
