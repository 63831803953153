import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ButtonVariants, ThemeVariants } from '../../constants'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import {
    buttonBackgroundColorValue,
    buttonLabelColorValue,
    buttonBorderValue,
    borderRadiusValue,
} from '../../constants/cssRules'
import Icon from '../Icon'

/**
 * Returns the icon variant based on the provided parameters.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.disabled - Indicates if the button is disabled.
 * @param {string} options.variant - The button variant.
 * @returns {string} The icon variant.
 */
const getIconVariant = ({ disabled, variant }) => {
    let iconVariant
    if (disabled) {
        iconVariant = ThemeVariants.DARK
    } else {
        switch (variant) {
            case ButtonVariants.MAIN:
                iconVariant = ThemeVariants.WHITE
                break
            case ButtonVariants.DANGER:
                iconVariant = ThemeVariants.WHITE
                break
            case ButtonVariants.DANGER_SECONDARY:
                iconVariant = ThemeVariants.ERROR
                break
            case ButtonVariants.SECONDARY:
                iconVariant = ThemeVariants.MAIN
                break
            default:
                iconVariant = ThemeVariants.MAIN
                break
        }
    }
    return iconVariant
}

const StyledButton = styled.button.withConfig(DEFAULT_CONFIG)(props => {
    const hasShadow = !props.disabled && props.variant === ButtonVariants.MAIN
    return {
        background: buttonBackgroundColorValue(props),
        color: buttonLabelColorValue(props),
        border: buttonBorderValue(props),
        borderRadius: borderRadiusValue(props),
        '&:hover': {
            background: props.disabled
                ? null
                : buttonBackgroundColorValue({
                      ...props,
                      isHover: true,
                  }),
            boxShadow: hasShadow
                ? `0 10px 40px -5px ${props.themeContext.main}90`
                : null,
        },
    }
})

const Button = ({
    id,
    cardHoverOnly,
    type,
    label,
    variant,
    filled,
    onClick,
    style,
    icon,
    className,
    disabled,
    title,
}) => {
    const themeContext = React.useContext(ThemeContext)
    return type === 'input' ? (
        <input
            type='submit'
            value={label}
            className='rds-btn'
            disabled={disabled}
        />
    ) : (
        <StyledButton
            className={`rds-btn ${disabled ? 'rds-btn_disabled' : ''} ${
                label ? 'rds-btn_labelled' : ''
            } rds-align-center justify-around ${
                cardHoverOnly ? 'rds-btn_card-hover' : ''
            } ${variant || ''} ${className || ''}`}
            themeContext={themeContext}
            id={id}
            variant={variant}
            onClick={!disabled ? onClick : undefined}
            type='button'
            style={style}
            labeled={label}
            disabled={disabled}
            title={title}
        >
            <div
                className='rds-flex rds-align-center'
                style={{ width: 'max-content' }}
            >
                {icon ? (
                    <Icon
                        type={icon}
                        variant={getIconVariant({ disabled, variant })}
                        theme={filled ? 'filled' : null}
                        size={15}
                    />
                ) : null}
                <span>{label}</span>
            </div>
        </StyledButton>
    )
}

export default Button
