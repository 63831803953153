import React from 'react'
import Template from '../layout/Template'
import { TableItem, Typography, TypographyTypes } from 'rds'

const TableItemTemplate = () => {
    return (
        <Template
            hashValue='table-item'
            componentName='TableItem'
            overview={
                <div>
                    <TableItem>
                        <Typography type={TypographyTypes.P}>
                            This is an item
                        </Typography>
                    </TableItem>
                    <TableItem>
                        <Typography type={TypographyTypes.P}>
                            This is another item
                        </Typography>
                    </TableItem>
                    <TableItem noLine>
                        <Typography type={TypographyTypes.P}>
                            This is the last item
                        </Typography>
                    </TableItem>
                </div>
            }
            example={`
<TableItem
    noLine={Boolean}
>
// Some content here...
</TableItem>`}
            properties={[
                {
                    name: 'noLine',
                    type: 'BOOLEAN',
                    description:
                        "If set to true, the item won't have the bottom line. This is useful for showing the user the last item in a list",
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class string',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
            ]}
        />
    )
}

export default TableItemTemplate
