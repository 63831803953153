import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
    cardBackgroundValue,
    borderValue,
    borderRadiusValue,
    boxShadowValue,
} from '../../constants/cssRules'
import { DEFAULT_CONFIG } from '../../constants/styledComponents'
import Header from '../Header'

export const StyledCard = styled.article.withConfig(DEFAULT_CONFIG)(props => ({
    background: cardBackgroundValue(props),
    border: borderValue(props),
    borderRadius: props.noBorderRadius ? '' : borderRadiusValue(props),
    boxShadow: boxShadowValue(props),
    cursor: props.onClick ? 'pointer' : null,
}))

const StyledCardHeader = styled.div.withConfig(DEFAULT_CONFIG)(props =>
    props.hasContent
        ? {
              marginTop: 'calc(var(--rds-spacingMedium) * -1)',
              padding: 'var(--rds-spacingMedium)',
              marginLeft: 'calc(var(--rds-spacingMedium) * -1)',
              width: 'calc(var(--rds-spacingMedium) * 2 + 100%)',
              marginBottom: 'var(--rds-spacingMedium)',
              borderBottom: borderValue(props),
          }
        : null,
)

const Card = ({
    children,
    className,
    onClick,
    style,
    onDoubleClick,
    onMouseOver,
    onMouseLeave,
    variant,
    id,
    noBorderRadius,
    align,
    headerTitle,
    headerText,
    headerSize,
    headerVariant,
    headerIcon,
}) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledCard
            themeContext={themeContext}
            className={`rds-card card rds-relative rds-p_around__md ${
                className || ''
            }`}
            style={style}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            variant={variant}
            id={id}
            align={align}
            noBorderRadius={noBorderRadius}
        >
            {headerTitle ? (
                <StyledCardHeader
                    themeContext={themeContext}
                    className='rds-card_header'
                    hasContent={!!children}
                >
                    <Header
                        title={headerTitle}
                        text={headerText}
                        size={headerSize}
                        variant={headerVariant}
                        icon={headerIcon}
                    />
                </StyledCardHeader>
            ) : null}
            {children}
        </StyledCard>
    )
}

export default Card
