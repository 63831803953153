export const rgbaToHex = (r, g, b) => {
    r = r.toString(16)
    g = g.toString(16)
    b = b.toString(16)
    if (r.length === 1) r = '0' + r
    if (g.length === 1) g = '0' + g
    if (b.length === 1) b = '0' + b
    return `#${r}${g}${b}`
}

export const hexDecrement = (hex, decrementAmount) => {
    if (!hex || !decrementAmount) {
        return
    }
    let r = 0,
        g = 0,
        b = 0
    // 3 digits
    if (hex.length === 4) {
        r = '0x' + hex[1] + hex[1]
        g = '0x' + hex[2] + hex[2]
        b = '0x' + hex[3] + hex[3]
        // 6 digits
    } else if (hex.length === 7) {
        r = '0x' + hex[1] + hex[2]
        g = '0x' + hex[3] + hex[4]
        b = '0x' + hex[5] + hex[6]
    }
    const derementedR = r - decrementAmount < 0 ? 0 : r - decrementAmount
    const derementedG = g - decrementAmount < 0 ? 0 : g - decrementAmount
    const derementedB = b - decrementAmount < 0 ? 0 : b - decrementAmount
    return rgbaToHex(derementedR, derementedG, derementedB)
}

/**
 * Gets the relative color in HSL format. This will allow a better control over
 * the color.
 * If a decrementation is wanted, the second parameter will use specific light and alpha values.
 *
 * @param {String} hex Original color in HEX format
 * @param {Number} light Overrides the calculated light value
 * @returns Color in HSL format
 */
export const hexToHsl = (hex, light, alpha = 1) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) {
        return
    }

    let r = parseInt(result[1], 16)
    let g = parseInt(result[2], 16)
    let b = parseInt(result[3], 16)
    let a
    ;(r /= 255), (g /= 255), (b /= 255)
    let max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    let h,
        s,
        l = (max + min) / 2

    if (max == min) {
        h = s = 0
    } else {
        let d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
        }
        h /= 6
    }

    h = Math.round(h * 360)
    s = Math.round(s * 100)
    l = light ? light : Math.round(l * 100)
    // If alpha parameter is not present, check whether there's a decrement
    // amount provided
    a = alpha === null || alpha === undefined ? (light ? 0.2 : 1) : alpha

    return 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + a + ')'
}

export const capitalizeFirst = text =>
    text.substr(0, 1).toUpperCase() + text.substr(1, text.length)

export const getMBSize = size => (size * 0.000001).toFixed(1)

export const isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a)
    var bProps = Object.getOwnPropertyNames(b)

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true
}

export const addEscListener = callback => {
    document.onkeydown = e => {
        e = e || window.e
        if (e.keyCode === 27 && callback) callback()
        return
    }
}

export const formatCardNumber = cardNumber => {
    const after = 4
    /* removes all characters in the value that aren't a number,
       or in the range from A to Z (uppercase): */
    const v = cardNumber.replace(/[^\dA-Z]/g, '')
    /* creating the regular expression, to allow for the 'after' variable
       to be used/changed: */
    const reg = new RegExp('.{' + after + '}', 'g')
    cardNumber = v.replace(reg, a => a + ' ')
    return cardNumber
}
