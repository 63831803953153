import React from 'react'
import Typography from './Typography'
import { TypographyTypes, Icons, ThemeVariants } from '../constants'
import Icon from './Icon'
import ComponentError from './States/ComponentError'

const Rater = ({
    values,
    initial = 0,
    onChange,
    showLabel = false,
    size = 15,
    className,
    id,
}) => {
    const [currentRate, setCurrentRate] = React.useState(initial)

    const handleClick = value => {
        onChange(value)
        setCurrentRate(value)
    }

    if (!values) {
        return (
            <ComponentError
                componentName='Rater'
                errorMessage='A "values" prop needs to be provided'
            />
        )
    }
    return (
        <div className={`rds-rate ${className || ''}`} id={id}>
            {showLabel ? (
                <header className='rds-rate_header'>
                    <Typography type={TypographyTypes.H3}>
                        {values[currentRate]}
                    </Typography>
                </header>
            ) : null}
            <div className='rds-rate_stars rds-flex rds-align-center'>
                {values.map((value, index) => {
                    const isSelected = currentRate >= index
                    return (
                        <div
                            key={index}
                            onClick={
                                onChange
                                    ? () => handleClick(index, value)
                                    : null
                            }
                            className={`rds-rate_selector-item rds-m_right__sm ${
                                onChange
                                    ? 'rds-rate_selector-item_interactive'
                                    : ''
                            } ${
                                isSelected
                                    ? 'rds-rate_selector-item_active'
                                    : ''
                            }`}
                        >
                            <Icon
                                type={Icons.STAR}
                                theme={isSelected ? 'filled' : 'outline'}
                                variant={ThemeVariants.WARNING}
                                size={size}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Rater
