import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { DEFAULT_CONFIG } from '../constants/styledComponents'

const StyledHeaderBreadcrumb = styled.span.withConfig(DEFAULT_CONFIG)`
    ${props => props.themeContext && ``}
`

const HeaderBreadcrumb = ({
    value,
    className,
    id,
    style,
    withSeparator,
    link,
    onClick,
}) => {
    const themeContext = React.useContext(ThemeContext)

    const getValue = () => (
        <React.Fragment>
            <span className='breadcrumb_value'>{value}&nbsp;</span>
            {withSeparator ? (
                <span className='breadcrumb_separator'>/</span>
            ) : null}
            &nbsp;
        </React.Fragment>
    )

    const getContent = () => {
        if (link) {
            return (
                <a style={{ display: 'block' }} href={link}>
                    {getValue()}
                </a>
            )
        }
        return getValue()
    }

    return (
        <StyledHeaderBreadcrumb
            id={id}
            themeContext={themeContext}
            className={`breadcrumb ${className}`}
            onClick={onClick}
            style={style}
        >
            {getContent()}
        </StyledHeaderBreadcrumb>
    )
}

export default HeaderBreadcrumb
