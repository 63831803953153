import React from 'react'
import { useParams } from 'react-router-dom'
import { components } from '../constants'

const Component = props => {
    const params = useParams()
    const componentReference = components.find(
        component => component.slug === params.componentId,
    )
    return React.createElement(componentReference.component, props)
}

export default Component
