import React from 'react'
import { HeaderSizes } from '../../constants'
import Icon from '../Icon'
import Header from '../Header'
import Tag from '../Tag'

const StateWrapper = ({
    title,
    message,
    headerSize = HeaderSizes.SMALL,
    centered,
    style,
    icon,
    iconSize = 30,
    link,
}) => {
    return (
        <div
            className={`rds-state rds-relative ${
                centered ? 'rds-centered rds-text-align_center' : ''
            }`}
            style={style}
        >
            <Icon className='rds-m_bottom__sm' type={icon} size={iconSize} />
            <Header title={title} text={message} size={headerSize}>
                {link ? (
                    <a href={link.url}>
                        <Tag>{link.label}</Tag>
                    </a>
                ) : null}
            </Header>
        </div>
    )
}

export default StateWrapper
