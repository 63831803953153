import CardTemplate from '../components/CardTemplate'
import CollapsibleTemplate from '../components/CollapsibleTemplate'
import ChatTemplate from '../components/ChatTemplate'
import CodeBlockTemplate from '../components/CodeBlockTemplate'
import FormTemplate from '../components/FormTemplate'
import HeaderTemplate from '../components/HeaderTemplate'
import ButtonTemplate from '../components/ButtonTemplate'
import StatesTemplate from '../components/StatesTemplate'
import GraphTemplate from '../components/GraphTemplate'
import IconTemplate from '../components/IconTemplate'
import TableTemplate from '../components/TableTemplate'
import TagTemplate from '../components/TagTemplate'
import BreadcrumbsTemplate from '../components/BreadcrumbsTemplate'
import FormItemTemplate from '../components/FormItemTemplate'
import FormSectionTemplate from '../components/FormSectionTemplate'
import ButtonGroupTemplate from '../components/ButtonGroupTemplate'
import EmojiTemplate from '../components/EmojiTemplate'
import TypographyTemplate from '../components/TypographyTemplate'
import LogoTemplate from '../components/LogoTemplate'
import IsologoTemplate from '../components/IsologoTemplate'
import LabelTemplate from '../components/LabelTemplate'
import PathTemplate from '../components/PathTemplate'
import ProgressBarTemplate from '../components/ProgressBarTemplate'
import TableItemTemplate from '../components/TableItemTemplate'
import BlockTemplate from '../components/BlockTemplate'
import RaterTemplate from '../components/RaterTemplate'
import CarrouselTemplate from '../components/CarrouselTemplate'

export const components = Object.freeze([
    { name: 'Block', slug: 'block', component: BlockTemplate },
    {
        name: 'Breadcrumbs',
        slug: 'breadcrumbs',
        component: BreadcrumbsTemplate,
    },
    { name: 'Button', slug: 'button', component: ButtonTemplate },
    {
        name: 'ButtonGroup',
        slug: 'button-group',
        component: ButtonGroupTemplate,
    },
    { name: 'Card', slug: 'card', component: CardTemplate },
    { name: 'Carrousel', slug: 'carrousel', component: CarrouselTemplate },
    { name: 'Chat', slug: 'chat', component: ChatTemplate },
    { name: 'CodeBlock', slug: 'code-block', component: CodeBlockTemplate },
    {
        name: 'Collapsible',
        slug: 'collapsible',
        component: CollapsibleTemplate,
    },
    { name: 'Emoji', slug: 'emoji', component: EmojiTemplate },
    { name: 'Form', slug: 'form', component: FormTemplate },
    { name: 'FormItem', slug: 'form-item', component: FormItemTemplate },
    {
        name: 'FormSection',
        slug: 'form-section',
        component: FormSectionTemplate,
    },
    { name: 'Graph', slug: 'graph', component: GraphTemplate },
    { name: 'Header', slug: 'header', component: HeaderTemplate },
    { name: 'Icon', slug: 'icon', component: IconTemplate },
    { name: 'Isologo', slug: 'isologo', component: IsologoTemplate },
    { name: 'Label', slug: 'label', component: LabelTemplate },
    { name: 'Logo', slug: 'logo', component: LogoTemplate },
    { name: 'Path', slug: 'path', component: PathTemplate },
    {
        name: 'ProgressBar',
        slug: 'progress-bar',
        component: ProgressBarTemplate,
    },
    { name: 'Rater', slug: 'rater', component: RaterTemplate },
    { name: 'States', slug: 'states', component: StatesTemplate },
    { name: 'Table', slug: 'table', component: TableTemplate },
    { name: 'TableItem', slug: 'table-item', component: TableItemTemplate },
    { name: 'Tag', slug: 'tag', component: TagTemplate },
    { name: 'Typography', slug: 'typography', component: TypographyTemplate },
])
