import { hexDecrement, hexToHsl } from '../utils'
import { ThemeVariants, ThemesNames, ButtonVariants } from '.'

// CONSTANTS
const RDS_VARIABLES_PREFIX = '--rds-'
const DEFAULT_BORDER_WIDTH = 1
export const BORDER_WIDTH_VALUE = `${DEFAULT_BORDER_WIDTH}px`
export const SIDEBAR_ICON_WIDTH = `17px`

/**
 * Returns the prefixed variable name
 *
 * @param {string} name Identifier name of the variable to retrieve\
 * @returns {string} Prefixed variable identifier
 */
export const getCSSVariable = name => `var(${RDS_VARIABLES_PREFIX}${name})`

// COLORS
// Dynamic colors
export const COLOR_PRIMARY_DEFAULT = '#2a6bef'
export const COLOR_SECONDARY_DEFAULT = '#80d4ff'
// Static colors
export const COLOR_WHITE = getCSSVariable('colorWhite')
export const COLOR_ERROR = getCSSVariable('colorError')
export const COLOR_ERROR_TRANSPARENT = getCSSVariable('colorErrorTransparent')
export const COLOR_ERROR_TRANSPARENT__HOVER = getCSSVariable(
    'colorErrorTransparentHover',
)
export const COLOR_WARNING = getCSSVariable('colorWarning')
export const COLOR_WARNING_TRANSPARENT = getCSSVariable(
    'colorWarningTransparent',
)
export const COLOR_GREEN = getCSSVariable('colorGreen')
export const COLOR_GREEN_TRANSPARENT = getCSSVariable('colorGreenTransparent')
export const COLOR_PURPLE = getCSSVariable('colorPurple')
export const COLOR_PURPLE_TRANSPARENT = getCSSVariable('colorPurpleTransparent')
export const COLOR_DISABLED__LIGHT = getCSSVariable('colorDisabledLight')
export const COLOR_DISABLED__DARK = getCSSVariable('colorDisabledDark')

/**
 * Returns hexadecimal main background color value
 * @param {Object} props Properties object containing themeContext property
 * @returns Hexadecimal main background color value
 */
export const mainBackgroundValue = props =>
    props.themeContext.scheme === ThemesNames.DARK ? `#1d1d1d` : `#fbfbfb`

/**
 * Returns the hexadecimal main backgrpound color CSS rule
 * @param {Object} props Properties object containing themeContext property
 * @returns Hexadecimal main backgrpound color CSS rule
 */
export const mainBackgroundRule = props =>
    props.themeContext.main && `background: ${mainBackgroundValue(props)};`

/**
 * Returns the hexadecimal black color value
 * @param {Object} themeContext ThemeContext object
 * @returns Hexadecimal black color value
 */
export const blackValue = themeContext =>
    getCSSVariable(
        themeContext.scheme === ThemesNames.DARK
            ? 'colorBlackDark'
            : 'colorBlackLight',
    )

/**
 * Returns the hexadecimal value of border colors
 * @param {Object} props ThemeContext wrapper object
 * @param {Boolean} isHovered Whether the hovered mode should be applied or not
 * @returns Hexadecimal border color
 */
export const borderColorValue = (
    props,
    isHovered = false,
    hasError = false,
) => {
    if (hasError) {
        return COLOR_ERROR
    }
    switch (props.variant) {
        case ThemeVariants.MAIN_LIGHT:
            return props.themeContext.main
        case ThemeVariants.SECONDARY_LIGHT:
            return props.themeContext.secondary
        case ThemeVariants.SECONDARY:
            return props.themeContext.secondary
        case ThemeVariants.ERROR:
            return COLOR_ERROR
        case ThemeVariants.WARNING:
            return COLOR_WARNING
        case ThemeVariants.TRANSPARENT:
            return 'transparent'
        default:
            return props.themeContext.scheme === ThemesNames.LIGHT
                ? hexToHsl(props.themeContext.main, 30, isHovered ? 0.3 : 0.15)
                : getCSSVariable('colorBorderDark')
    }
}

/**
 * Returns the border width value in pixels. *
 * @returns Value of border width in pixels
 */
export const borderWidthValue = props =>
    `${props.themeContext.borderWidth || 0}px`

/**
 * Returns the CSS border value
 * @param {Objects} props Properties object containing themeContext property
 * @param {Boolean} isHovered Whether the hovered mode should be applied or not
 * @returns CSS rule for border radius
 */
export const borderValue = (props, isHovered, hasError) => {
    if (props.variant === ThemeVariants.GLOSSY) {
        return 'unset'
    }
    return `${borderWidthValue(props)} ${borderColorValue(
        props,
        isHovered,
        hasError,
    )} ${props.variant === ThemeVariants.DASHED ? 'dashed' : 'solid'}`
}

/**
 * Returns the CSS rule for borders
 * @param {Objects} props Properties object containing themeContext property
 * @param {Boolean} isHovered Whether the hovered mode should be applied or not
 * @returns CSS rule for borders
 */
export const borderRule = (props, isHovered, hasError) =>
    `border: ${borderValue(props, isHovered, hasError)};`

/**
 * Returns the CSS value for borders
 * @param {Object} props Properties object containing themeContext property
 * @returns CSS value for borders
 */
export const borderRadiusValue = props =>
    `${props.themeContext.borderRadius || 0}px`

/**
 * Returns the CSS value for borders
 * @param {Object} props Properties object containing themeContext property
 * @returns CSS value for borders
 */
export const borderRadiusRule = props =>
    `border-radius: ${borderRadiusValue(props)};`

/**
 * Returns the CSS value for button background color
 * @param {Object} props Properties object containing themeContext property
 * @returns CSS value for button background color
 */
export const buttonBackgroundColorValue = ({
    variant,
    themeContext,
    isHover,
    disabled,
}) => {
    const { main, scheme } = themeContext
    if (disabled) {
        return scheme === ThemesNames.LIGHT
            ? COLOR_DISABLED__LIGHT
            : COLOR_DISABLED__DARK
    }
    switch (variant) {
        case ButtonVariants.MAIN:
            return main
        case ButtonVariants.DANGER:
            return COLOR_ERROR
        case ButtonVariants.DANGER_SECONDARY:
            return `${
                isHover
                    ? COLOR_ERROR_TRANSPARENT__HOVER
                    : COLOR_ERROR_TRANSPARENT
            }`
        case ButtonVariants.DARK:
            return hexToHsl(main, 10, 1)
        case ButtonVariants.BLACK:
            return blackValue(themeContext)
        case ButtonVariants.TRANSPARENT:
            return isHover
                ? scheme === ThemesNames.LIGHT
                    ? hexToHsl(main, 97)
                    : `${main}20`
                : 'transparent'
        case ButtonVariants.WHITE:
            return 'white'
        default:
            return hexToHsl(main, scheme === ThemesNames.LIGHT ? 97 : 80)
    }
}

export const buttonLabelColorValue = ({ disabled, variant, themeContext }) => {
    if (disabled) {
        return blackValue(themeContext)
    }
    switch (variant) {
        case ButtonVariants.MAIN:
            return COLOR_WHITE
        case ButtonVariants.BLACK:
            return COLOR_WHITE
        case ButtonVariants.DARK:
            return COLOR_WHITE
        case ButtonVariants.DANGER:
            return COLOR_WHITE
        case ButtonVariants.DANGER_SECONDARY:
            return COLOR_ERROR
        case ButtonVariants.WHITE:
            return blackValue(themeContext)
        default:
            return themeContext.main
    }
}

export const buttonBorderColorValue = ({ disabled, variant, themeContext }) => {
    let color
    const { main, scheme } = themeContext
    if (disabled) {
        color =
            scheme === ThemesNames.LIGHT
                ? COLOR_DISABLED__LIGHT
                : COLOR_DISABLED__DARK
    } else {
        switch (variant) {
            case ButtonVariants.MAIN:
                color = main
                break
            case ButtonVariants.TRANSPARENT:
                color = 'transparent'
                break
            case ButtonVariants.DANGER:
                color = getCSSVariable('colorErrorTransparent')
                break
            case ButtonVariants.DANGER_SECONDARY:
                color = getCSSVariable('colorErrorTransparent')
                break
            default:
                color = hexToHsl(main, 50, 0.2)
                break
        }
    }
    return color
}

export const buttonBorderValue = props =>
    `${borderWidthValue(props)} ${buttonBorderColorValue(props)} solid`

export const buttonBorderRule = props => `border: ${buttonBorderValue(props)};`

export const darkBackgroundRule = props =>
    `background-color: ${getCSSVariable(
        props.themeContext.scheme === ThemesNames.DARK
            ? 'colorGrayDark'
            : 'colorGrayLight',
    )};`

export const blackBackgroundRule = props =>
    `background-color: ${blackValue(props.themeContext)};`

export const variantBackgroundValue = props => {
    const lightOpacity = '2e'
    switch (props.variant) {
        case ThemeVariants.DARK:
            return blackValue(props.themeContext)

        case ThemeVariants.SECONDARY:
            return props.themeContext.secondary

        case ThemeVariants.SECONDARY_LIGHT:
            return `${hexDecrement(
                props.themeContext.secondary,
                40,
            )}${lightOpacity}`

        case ThemeVariants.WARNING:
            return COLOR_WARNING_TRANSPARENT

        case ThemeVariants.ERROR:
            return COLOR_ERROR_TRANSPARENT

        case ThemeVariants.MAIN:
            return props.themeContext.main

        case ThemeVariants.MAIN_LIGHT:
            return `${hexDecrement(props.themeContext.main, 40)}${lightOpacity}`

        case ThemeVariants.GLOSSY:
            return `linear-gradient(45deg, ${props.themeContext.main}40, ${props.themeContext.secondary}40)`

        case ThemeVariants.GRAY:
            return getCSSVariable(
                props.themeContext.scheme === ThemesNames.DARK
                    ? 'colorGrayDark'
                    : 'colorGrayLight',
            )

        case ThemeVariants.WHITE:
            return COLOR_WHITE

        case ThemeVariants.GREEN:
            return COLOR_GREEN_TRANSPARENT

        case ThemeVariants.PURPLE:
            return COLOR_PURPLE_TRANSPARENT

        default:
            return ''
    }
}

export const backgroundValue = props => {
    let value = ''
    if (props.disabled) {
        value =
            props.themeContext.scheme === ThemesNames.DARK
                ? COLOR_DISABLED__DARK
                : COLOR_DISABLED__LIGHT
    } else if (props.variant) {
        value = variantBackgroundValue(props)
    } else {
        if (props.themeContext.scheme === ThemesNames.DARK) {
            value = getCSSVariable('colorGrayDark')
        } else {
            value = getCSSVariable('colorGrayLight')
        }
    }
    return value
}

export const backgroundRule = props => `background: ${backgroundValue(props)};`

export const cardBackgroundValue = props => {
    let colorValue = ''
    if (props.disabled) {
        colorValue =
            props.themeContext.scheme === ThemesNames.DARK
                ? COLOR_DISABLED__DARK
                : COLOR_DISABLED__LIGHT
    } else if (props.variant) {
        colorValue = variantBackgroundValue(props)
    } else {
        if (props.themeContext.scheme === ThemesNames.DARK) {
            colorValue = getCSSVariable('colorCardDark')
        } else {
            colorValue = getCSSVariable('colorCardLight')
        }
    }
    return colorValue
}

export const cardBackgroundRule = props => {
    return `background: ${cardBackgroundValue(props)};`
}

export const iconBackgroundRule = props => {
    let colorValue = ''
    switch (props.variant) {
        case ThemeVariants.MAIN || ThemeVariants.MAIN_LIGHT:
            colorValue = hexToHsl(
                props.themeContext.main,
                props.themeContext.scheme === ThemesNames.LIGHT ? 95 : 75,
            )
            break

        case ThemeVariants.SECONDARY || ThemeVariants.SECONDARY_LIGHT:
            colorValue = hexToHsl(props.themeContext.secondary, 85)
            break

        case ThemeVariants.GRAY:
            colorValue = getCSSVariable(
                props.themeContext.scheme === ThemesNames.DARK
                    ? 'colorGrayDark'
                    : 'colorGrayLight',
            )
            break

        default:
            colorValue = 'transparent'
            break
    }
    return `background: ${colorValue};`
}

export const secondaryBackgroundRule = props =>
    `background-color: ${props.themeContext.secondary};`

export const colorValue = props => {
    let colorValue = ''
    switch (props.variant) {
        case ThemeVariants.MAIN:
            colorValue = hexDecrement(props.themeContext.main, 15)
            break
        case ThemeVariants.SECONDARY:
            colorValue = hexDecrement(props.themeContext.secondary, 15)
            break
        case ThemeVariants.ERROR:
            colorValue = COLOR_ERROR
            break
        case ThemeVariants.WARNING:
            colorValue = COLOR_WARNING
            break
        case ThemeVariants.WHITE:
            colorValue = `#fffffff2`
            break
        case ThemeVariants.DARK:
            colorValue = blackValue(props.themeContext)
            break
        case ThemeVariants.LINE_THROUGH:
            colorValue =
                props.themeContext.scheme === ThemesNames.DARK
                    ? COLOR_WHITE
                    : `${blackValue(props.themeContext)}50`
            break
        case ThemeVariants.GREEN:
            colorValue = COLOR_GREEN
            break
        case ThemeVariants.PURPLE:
            colorValue = COLOR_PURPLE
            break
        default:
            colorValue =
                props.themeContext.scheme === ThemesNames.DARK
                    ? COLOR_WHITE
                    : blackValue(props.themeContext)
            break
    }
    if (props.variant) {
        colorValue += ` !important`
    }
    return colorValue
}

export const colorRule = props => {
    const resultantColorValue = colorValue(props)
    return `
        color: ${resultantColorValue};
        border-bottom-color: ${resultantColorValue};
        & * {
            color: ${resultantColorValue};
        }
    `
}

export const labelColorValue = props => {
    return props.themeContext.scheme === ThemesNames.DARK ||
        props.variant === ThemeVariants.WHITE
        ? `#afafaf`
        : `#757575`
}

export const labelColorRule = props => `color: ${labelColorValue(props)};`

export const softShadowValue = props =>
    props.themeContext.scheme === ThemesNames.DARK
        ? // No soft shadow in DARK MODE
          'unset'
        : props.variant !== ThemeVariants.SECONDARY
          ? `0 10px 35px -15px ${blackValue(props.themeContext)}15`
          : // No soft shadow when its a variant = SECONDARY
            'unset'

export const softShadowRule = props => `box-shadow: ${softShadowValue(props)};`

export const fontSizeRule = fontSize => `
    font-size: ${fontSize};
    & * {
        font-size: ${fontSize};
    }
`

export const textDecorationValue = variant => {
    switch (variant) {
        case ThemeVariants.LINE_THROUGH:
            return 'line-through'
        default:
            return 'unset'
    }
}

export const textDecorationRule = variant =>
    `text-decoration: ${textDecorationValue(variant)};`

export const boxShadowValue = props => {
    if (props.variant === ThemeVariants.DASHED) {
        return 'unset'
    }
    return `rgb(0 0 0 / ${
        props.themeContext.scheme === ThemesNames.LIGHT ? 6 : 30
    }%) 0px 4px 7px 0px`
}

export const boxShadowRule = props => `box-shadow: ${boxShadowValue(props)};`
