import React from 'react'
import Template from '../layout/Template'
import { Block, Carrousel, Header, HeaderSizes } from 'rds'

const THUMBNAIL_SIZE = 110
const PICTURE_BASE_URI = 'https://picsum.photos/id/'

const getRandomPicture = index => {
    return `${PICTURE_BASE_URI}${index}/${THUMBNAIL_SIZE * 2}/${
        THUMBNAIL_SIZE * 2
    }`
}

const items = [
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum dolor',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum dolor sit amet',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
    {
        title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet',
    },
]
items.forEach((item, index) => {
    item.title = `${index + 1}. ${item.title}`
    item.thumbnail = getRandomPicture(index * 10)
})

const CarrouselTemplate = () => {
    return (
        <Template
            hashValue='carrousel'
            componentName='Carrousel'
            overview={
                <div style={{ maxWidth: '60vw', margin: 'auto' }}>
                    <div>
                        <Header title='One stepper' size={HeaderSizes.SMALL} />
                        <Carrousel className='rds-m_top__sm'>
                            {items.map((item, index) => (
                                <Block
                                    className='rds-overflow_hidden'
                                    key={index}
                                    bordered
                                    roundedBorder
                                >
                                    <img
                                        src={item.thumbnail}
                                        width='100%'
                                        height={THUMBNAIL_SIZE}
                                        alt={index}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    <Header
                                        className='rds-p_around__md'
                                        title={item.title}
                                        text={item.text}
                                        size={HeaderSizes.SMALL}
                                    />
                                </Block>
                            ))}
                        </Carrousel>
                    </div>
                    <div className='rds-m_top__md'>
                        <Header
                            title='One stepper, with overflow'
                            size={HeaderSizes.SMALL}
                        />
                        <Carrousel className='rds-m_top__sm' withOverflow>
                            {items.map((item, index) => (
                                <Block
                                    className='rds-overflow_hidden'
                                    key={index}
                                    bordered
                                    roundedBorder
                                >
                                    <img
                                        src={item.thumbnail}
                                        width='100%'
                                        height={THUMBNAIL_SIZE}
                                        alt={index}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    <Header
                                        className='rds-p_around__md'
                                        title={item.title}
                                        text={item.text}
                                        size={HeaderSizes.SMALL}
                                    />
                                </Block>
                            ))}
                        </Carrousel>
                    </div>
                    <div className='rds-m_top__md'>
                        <Header
                            title='Two stepper, with overflow'
                            size={HeaderSizes.SMALL}
                        />
                        <Carrousel
                            steps={2}
                            className='rds-m_top__sm'
                            withOverflow
                        >
                            {items.map((item, index) => (
                                <Block
                                    className='rds-overflow_hidden'
                                    key={index}
                                    bordered
                                    roundedBorder
                                >
                                    <img
                                        src={item.thumbnail}
                                        width='100%'
                                        height={THUMBNAIL_SIZE}
                                        alt={index}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    <Header
                                        className='rds-p_around__md'
                                        title={item.title}
                                        text={item.text}
                                        size={HeaderSizes.SMALL}
                                    />
                                </Block>
                            ))}
                        </Carrousel>
                    </div>
                    <div className='rds-m_top__md'>
                        <Header
                            title='Three items per page, with overflow'
                            size={HeaderSizes.SMALL}
                        />
                        <Carrousel
                            itemsPerPage={3}
                            className='rds-m_top__sm'
                            withOverflow
                        >
                            {items.map((item, index) => (
                                <Block
                                    className='rds-overflow_hidden'
                                    key={index}
                                    bordered
                                    roundedBorder
                                >
                                    <img
                                        src={item.thumbnail}
                                        width='100%'
                                        height={THUMBNAIL_SIZE * 2}
                                        alt={index}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    <Header
                                        className='rds-p_around__md'
                                        title={item.title}
                                        text={item.text}
                                        size={HeaderSizes.SMALL}
                                    />
                                </Block>
                            ))}
                        </Carrousel>
                    </div>
                </div>
            }
            example={`
<Carrousel>
    {items.map((item, index) => (
        <Card
            key={index}
            headerTitle={item.title}
            headerText={item.text}
            headerSize={HeaderSizes.SMALL}
        />
    ))}
</Carrousel>`}
            properties={[
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class string',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'children',
                    type: 'ARRAY',
                    description: 'Array of HTML elements / React components',
                    required: true,
                },
                {
                    name: 'itemsPerPage',
                    type: 'NUMBER',
                    description: 'Maximum number of items shown per page',
                },
                {
                    name: 'steps',
                    type: 'NUMBER',
                    description: 'Amount of items that are slided back',
                },
                {
                    name: 'withOverflow',
                    type: 'BOOLEAN',
                    description:
                        'Whether the Carrousel should display items outside its container',
                },
                {
                    name: 'firstBreakPoint',
                    type: 'NUMBER',
                    description:
                        'Width in pixels where the Carrousel will behave as a mobile component, default is 800',
                },
            ]}
        />
    )
}

export default CarrouselTemplate
