import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Block, ThemesNames, cssRules } from 'rds'
import { ThemeProvider } from 'styled-components'
import 'rds/dist/index.css'

import Head from './layout/Head'
import Sidebar from './layout/Sidebar'
import Component from './pages/Component'
import Main from './pages/Main'
import ScrollTop from './layout/ScrollTop'

const App = () => {
    const [headCollapsed, setHeadCollapsed] = React.useState(false)
    const [themeScheme, setThemeScheme] = React.useState(ThemesNames.LIGHT)

    const theme = {
        // This is to avoid black theme when no
        // userConfig is retrieved
        main: cssRules.COLOR_PRIMARY_DEFAULT,
        secondary: cssRules.COLOR_SECONDARY_DEFAULT,
        scheme: themeScheme,
        borderRadius: 5,
        borderWidth: 1,
    }

    React.useEffect(() => {
        const sessionScheme = localStorage.getItem('rds_scheme')
        if (sessionScheme) {
            setThemeScheme(sessionScheme)
        }
    }, [])

    const handleMainScroll = e => {
        if (e.target.id !== 'main-content') return
        if (e.target.scrollTop > 10) {
            setHeadCollapsed(true)
        } else {
            setHeadCollapsed(false)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <React.Fragment>
                    <ScrollTop />
                    <Block
                        className='rds-full-block rds-flex'
                        style={{ flexDirection: 'column' }}
                    >
                        <Head
                            setThemeScheme={setThemeScheme}
                            themeScheme={themeScheme}
                            collapsed={headCollapsed}
                        />
                        <div className='main-wrapper rds-full-block'>
                            <Sidebar />
                            <div
                                id='main-content'
                                className='rds-p_around'
                                onScroll={handleMainScroll}
                            >
                                <div id='main-content_inner'>
                                    <Routes>
                                        <Route
                                            path='/components/:componentId'
                                            element={<Component />}
                                        />
                                        <Route path='/' element={<Main />} />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </Block>
                </React.Fragment>
            </Router>
        </ThemeProvider>
    )
}

export default App
