import React from 'react'
import { ThemeVariants, ButtonVariants, HeaderSizes } from '../../constants'
import Header from '../Header'
import Button from '../Button'
import Block from '../Block'
import EmptyState from '../States/EmptyState'
import Textarea from '../Form/Textarea'
import ChatMessage from './ChatMessage'
import { borderValue } from '../../constants/cssRules'
import { ThemeContext } from 'styled-components'

const Chat = props => {
    const {
        title,
        useInterval = false,
        delay = 4000,
        emptyStateTitle = 'No messages yet',
        emptyStateText,
        onFetch,
        onSubmit,
        messagePlaceholder = 'Write a message',
        sessionUserId,
        messages,
        disabled,
    } = props
    const themeContext = React.useContext(ThemeContext)
    const scrollableRef = React.createRef()
    const submitNewMessageButtonRef = React.createRef()
    const [isSendingMessage, setIsSendingMessage] = React.useState()
    const [messagesShadow, setMessagesShadow] = React.useState([])
    const [newMessage, setNewMessage] = React.useState()
    const mounted = React.useRef()
    const interval = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    })

    React.useEffect(() => {
        if (typeof onFetch === 'function') {
            onFetch()
        }
    }, [onFetch])

    React.useEffect(() => {
        if (!useInterval) {
            return
        }

        if (interval.current) {
            clearInterval(interval.current)
        }
        interval.current = setInterval(() => {
            if (typeof onFetch === 'function') {
                onFetch()
            }
        }, delay)

        // Clean-up on unmount
        return () => {
            if (interval.current) {
                clearInterval(interval.current)
            }
        }
    }, [delay, onFetch, useInterval])

    /**
     * Scrolls chat to bottom every time there's a change in the
     * list of comments (a new comment is added)
     */
    React.useEffect(() => {
        if (!scrollableRef.current) {
            return
        }
        const previousCaseCommentsSize = messagesShadow.length
        setMessagesShadow(messages)
        if (messages.length === previousCaseCommentsSize) {
            return
        }
        scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight
    }, [scrollableRef, messages, messagesShadow.length])

    const handleSendNewMessage = async () => {
        submitNewMessageButtonRef.current.click()
    }

    const handleNewMessageSubmit = async e => {
        e.preventDefault()
        e.stopPropagation()
        try {
            setIsSendingMessage(true)
            if (typeof onSubmit === 'function') {
                await onSubmit(newMessage)
            }
            // Clear input
            setNewMessage('')
        } catch (error) {
            console.error('[CHAT]', error)
        } finally {
            setIsSendingMessage(false)
        }
    }

    const handleFormKeyDown = e => {
        if (e.ctrlKey && e.keyCode === 13) {
            handleSendNewMessage()
        }
    }

    return (
        <Block
            {...props}
            className={`rds-m_top__sm rds-full-block ${props.className || ''}`}
            variant={ThemeVariants.GRAY}
            roundedBorder
            bordered
        >
            <Header
                className='rds-p_around__md'
                title={title || 'Chat'}
                size={HeaderSizes.SMALL}
            />
            <div
                ref={scrollableRef}
                className='rds-m_top__md rds-p_around__md'
                style={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                }}
            >
                {!messages.length ? (
                    <EmptyState
                        title={emptyStateTitle}
                        message={emptyStateText}
                    />
                ) : (
                    messages.map((message, i) => (
                        <div
                            key={i}
                            className='rds-full-block rds-m_bottom__sm'
                        >
                            <ChatMessage
                                message={message}
                                sessionUserId={sessionUserId}
                            />
                        </div>
                    ))
                )}
            </div>
            <div className='rds-m_top__md rds-full-block'>
                <form
                    onSubmit={handleNewMessageSubmit}
                    onKeyDown={handleFormKeyDown}
                >
                    <Block
                        className='rds-grid rds-p_around__md'
                        style={{
                            gridTemplateColumns: '89% 10%',
                            borderTop: borderValue({ themeContext }),
                        }}
                    >
                        <Textarea
                            placeholder={messagePlaceholder}
                            onChange={e => setNewMessage(e.target.value)}
                            value={newMessage}
                            disabled={disabled || isSendingMessage}
                        />
                        <Button
                            variant={
                                disabled || isSendingMessage || !newMessage
                                    ? undefined
                                    : ButtonVariants.MAIN
                            }
                            label={isSendingMessage ? 'Enviando' : 'Enviar'}
                            onClick={handleSendNewMessage}
                            disabled={
                                disabled || isSendingMessage || !newMessage
                            }
                        />
                    </Block>
                    <input
                        type='submit'
                        style={{ display: 'none' }}
                        ref={submitNewMessageButtonRef}
                    />
                </form>
            </div>
        </Block>
    )
}

export default Chat
