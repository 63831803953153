import React from 'react'
import { Header, Tag, HeaderSizes, ThemeVariants, IconTypes } from 'rds'
import Template from '../layout/Template'

const TagTemplate = () => {
    return (
        <Template
            hashValue='tags'
            componentName='Tag'
            overview={
                <div>
                    <section>
                        <Header title='Variants' size={HeaderSizes.SMALL} />
                        <div className='rds-m_top__sm'>
                            <Tag className='rds-m_right__sm'>Normal</Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.GREEN}
                            >
                                Green
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.PURPLE}
                            >
                                Purple
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.WARNING}
                            >
                                Warning
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.ERROR}
                            >
                                Alert
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.DARK}
                            >
                                Dark
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.DASHED}
                            >
                                Dashed
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.TRANSPARENT}
                            >
                                Transparent
                            </Tag>
                        </div>
                    </section>
                    <section className='rds-m_top__md'>
                        <Header
                            title='Variants with icons'
                            text='Use the "icon" property'
                            size={HeaderSizes.XSMALL}
                        />
                        <div className='rds-m_top__sm'>
                            <Tag className='rds-m_right__sm' icon='SmilingFace'>
                                Congrats!
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.GREEN}
                                icon={IconTypes.CALENDAR}
                            >
                                On time
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.PURPLE}
                                icon='Truck'
                            >
                                Delivered
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.WARNING}
                                icon={IconTypes.WARNING}
                            >
                                Warning
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.ERROR}
                                icon='Alarm'
                            >
                                Alert
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.DARK}
                                icon='Copy'
                            >
                                Copy
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.DASHED}
                                icon='HexagonOne'
                            >
                                Draft
                            </Tag>
                            <Tag
                                className='rds-m_right__sm'
                                variant={ThemeVariants.TRANSPARENT}
                                icon='Success'
                            >
                                Done
                            </Tag>
                        </div>
                    </section>
                    <section className='rds-m_top__md'>
                        <Header
                            title='Action tags'
                            text='Execute actions when they are clicked, just add the onClick property and you are done!'
                            size={HeaderSizes.XSMALL}
                        />
                        <div className='rds-m_top__sm'>
                            <Tag
                                onClick={() => alert('Tag action!')}
                                variant={ThemeVariants.DARK}
                                className='rds-m_right__sm'
                            >
                                Click me!
                            </Tag>
                            <Tag onClick={() => alert('Tag action!')} disabled>
                                Disabled
                            </Tag>
                        </div>
                    </section>
                </div>
            }
            example={`
<Tag
    variant={ThemeVariants.<VARIANT>}
    onClick={Function}
    style={Object}
    className={String}
    disabled={Boolean}
>
    // Content goes here...
</Tag>`}
            properties={[
                {
                    name: 'variant',
                    type: 'ThemeVariants',
                    description: "import { ThemeVariants } from 'rds'",
                },
                {
                    name: 'onClick',
                    type: 'FUNCTION',
                    description: 'Callback to execute when the Tag is clicked',
                },
                {
                    name: 'style',
                    type: 'OBJECT',
                    description: 'CSS object containing styles customizations',
                },
                {
                    name: 'id',
                    type: 'STRING',
                    description: 'HTML id attribute',
                },
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS class/es you want to add',
                },
                {
                    name: 'disabled',
                    type: 'BOOLEAN',
                    description: 'Whether the Tag is disabled or not',
                },
            ]}
        />
    )
}

export default TagTemplate
