import React from 'react'
import Template from '../layout/Template'
import {
    Header,
    Loading,
    HeaderSizes,
    EmptyState,
    DeletedState,
    ErrorState,
    NoImageState,
    Card,
} from 'rds'

const StatesTemplate = () => {
    return (
        <Template
            hashValue='states'
            componentName='State'
            overview={
                <div>
                    <section className='rds-m_top__md'>
                        <Header
                            title='Loading'
                            text='Show your users that is worth the wait'
                            size={HeaderSizes.SMALL}
                        />
                        <div className='rds-m_top__md rds-grid rds-grid_3-columns'>
                            <article>
                                <Header
                                    title='Loading with tag'
                                    text='Use it for small section loadings'
                                    size={HeaderSizes.XSMALL}
                                />
                                <div className='rds-m_top__md'>
                                    <Loading
                                        className='rds-m_top__sm'
                                        tag='Loading records...'
                                    />
                                </div>
                            </article>
                            <article>
                                <Header
                                    title='Loading centered'
                                    text='Use it for full screen loadings or for "position: relative" containers'
                                    size={HeaderSizes.XSMALL}
                                />
                                <div className='rds-full-block'>
                                    <Card
                                        className='rds-m_top__sm'
                                        style={{
                                            position: 'relative',
                                            height: '80px',
                                            width: '80px',
                                        }}
                                    >
                                        <Loading />
                                    </Card>
                                </div>
                            </article>
                        </div>
                    </section>
                    <section className='rds-m_top'>
                        <Header
                            title='Messages'
                            text='Give specific information about the state'
                            size={HeaderSizes.SMALL}
                        />
                        <div className='rds-m_top__sm rds-grid rds-grid_3-columns'>
                            <article>
                                <div className='rds-full-block rds-m_top__sm'>
                                    <EmptyState
                                        title='Nothing new here'
                                        message='Try adding some stuff'
                                    />
                                </div>
                            </article>
                            <article>
                                <div className='rds-full-block rds-m_top__sm'>
                                    <ErrorState
                                        title='Something happened'
                                        message='Try again later'
                                    />
                                </div>
                            </article>
                        </div>
                        <div className='rds-m_top__md rds-grid rds-grid_3-columns'>
                            <article>
                                <div className='rds-full-block rds-m_top__sm'>
                                    <DeletedState
                                        title='Not available'
                                        message='Item was removed'
                                    />
                                </div>
                            </article>
                            <article>
                                <div className='rds-full-block rds-m_top__sm'>
                                    <NoImageState
                                        title='No picture available'
                                        message='Try adding one'
                                    />
                                </div>
                            </article>
                        </div>
                    </section>
                </div>
            }
            example={`
<EmptyState
    title='Nothing new here'
    message='Try adding some stuff'
/>

<ErrorState
    title='Something happened'
    message='Try again later'
/>

<DeletedState
    title='Not available'
    message='Item was removed'
/>

<NoImageState
    title='No picture available
    message='Try adding one'
/>`}
            properties={[
                {
                    name: 'title',
                    type: 'STRING',
                    description: 'The title of the state',
                },
                {
                    name: 'message',
                    type: 'STRING',
                    description: 'A brief description of the state',
                },
                {
                    name: 'centered',
                    type: 'BOOLEAN',
                    description:
                        'Whether the content should be centered or not',
                },
            ]}
        />
    )
}

export default StatesTemplate
