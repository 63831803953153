import React from 'react'
import Template from '../layout/Template'
import { Button, ButtonVariants, ButtonGroup, IconTypes } from 'rds'

const ButtonGroupTemplate = () => {
    return (
        <Template
            hashValue='button-group'
            componentName='ButtonGroup'
            overview={
                <ButtonGroup>
                    <Button label='Back' icon={IconTypes.ARROW} />
                    <Button label='Restore defaults' icon={IconTypes.RESTORE} />
                    <Button
                        label='Save'
                        variant={ButtonVariants.MAIN}
                        icon={IconTypes.SAVE}
                    />
                </ButtonGroup>
            }
            example={`
<ButtonGroup>
    <Button label='Back' />
    <Button
        label='Restore defaults'
        icon={IconTypes.RESTORE}
    />
    <Button
        label='Save'
        variant={ButtonVariants.MAIN}
        icon={IconTypes.SAVE}
    />
</ButtonGroup>`}
            properties={[
                {
                    name: 'className',
                    type: 'STRING',
                    description: 'CSS Class selector',
                },
            ]}
        />
    )
}

export default ButtonGroupTemplate
