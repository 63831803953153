import React from 'react'
import Template from '../layout/Template'
import { Block, Card, HeaderSizes, Icon, ThemeVariants, Typography } from 'rds'

const IconTemplate = () => {
    return (
        <Template
            hashValue='icons'
            componentName='Icon'
            overview={
                <div>
                    <Card headerTitle='Library' headerSize={HeaderSizes.SMALL}>
                        <Typography>
                            RDS uses <b>IconPark</b>&apos;s icon library
                        </Typography>
                        <Typography>
                            Check its{' '}
                            <a
                                href='https://iconpark.oceanengine.com/official'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='rds-text-variant_link'
                            >
                                documentation
                            </a>{' '}
                            to see all icons available
                        </Typography>
                    </Card>
                    <Card
                        headerTitle='Variants'
                        headerSize={HeaderSizes.SMALL}
                        className='rds-m_top__sm'
                    >
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='People'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.MAIN}
                                />
                                <Typography>Filled main</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='People'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.MAIN}
                                />
                                <Typography>Outline main</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='People'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.MAIN}
                                />
                                <Typography>Two-tone main</Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Like'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.SECONDARY}
                                />
                                <Typography>Filled secondary</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Like'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.SECONDARY}
                                />
                                <Typography>Outline secondary</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Like'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.SECONDARY}
                                />
                                <Typography>Two-tone secondary</Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Lightning'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.DARK}
                                />
                                <Typography>Filled dark</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Lightning'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.DARK}
                                />
                                <Typography>Outline dark</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Lightning'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.DARK}
                                />
                                <Typography>Two-tone dark</Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                variant={ThemeVariants.DARK}
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Umbrella'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.GRAY}
                                />
                                <Typography variant={ThemeVariants.WHITE}>
                                    Filled gray
                                </Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                variant={ThemeVariants.DARK}
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Umbrella'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.GRAY}
                                />
                                <Typography variant={ThemeVariants.WHITE}>
                                    Outline gray
                                </Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                variant={ThemeVariants.DARK}
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Umbrella'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.GRAY}
                                />
                                <Typography variant={ThemeVariants.WHITE}>
                                    Two-tone gray
                                </Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                variant={ThemeVariants.DARK}
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Shield'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.WHITE}
                                />
                                <Typography variant={ThemeVariants.WHITE}>
                                    Filled white
                                </Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                variant={ThemeVariants.DARK}
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Shield'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.WHITE}
                                />
                                <Typography variant={ThemeVariants.WHITE}>
                                    Outline white
                                </Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                variant={ThemeVariants.DARK}
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Shield'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.WHITE}
                                />
                                <Typography variant={ThemeVariants.WHITE}>
                                    Two-tone white
                                </Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Alarm'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.ERROR}
                                />
                                <Typography>Filled error</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Alarm'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.ERROR}
                                />
                                <Typography>Outline error</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Alarm'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.ERROR}
                                />
                                <Typography>Two-tone error</Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Caution'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.WARNING}
                                />
                                <Typography>Filled warning</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Caution'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.WARNING}
                                />
                                <Typography>Outline warning</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Caution'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.WARNING}
                                />
                                <Typography>Two-tone warning</Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='CrossSociety'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.GREEN}
                                />
                                <Typography>Filled green</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='CrossSociety'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.GREEN}
                                />
                                <Typography>Outline green</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='CrossSociety'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.GREEN}
                                />
                                <Typography>Two-tone green</Typography>
                            </Block>
                        </div>
                        <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_bottom__sm'>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Car'
                                    theme='filled'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.PURPLE}
                                />
                                <Typography>Filled purple</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Car'
                                    theme='outline'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.PURPLE}
                                />
                                <Typography>Outline purple</Typography>
                            </Block>
                            <Block
                                className='rds-p_around__sm rds-flex rds-align-center'
                                bordered
                                roundedBorder
                            >
                                <Icon
                                    size={25}
                                    type='Car'
                                    theme='two-tone'
                                    className='rds-m_right__sm'
                                    variant={ThemeVariants.PURPLE}
                                />
                                <Typography>Two-tone purple</Typography>
                            </Block>
                        </div>
                    </Card>
                    <Card
                        headerTitle='Animations'
                        headerText='You can apply animations by just adding the "spin" boolean property'
                        headerSize={HeaderSizes.SMALL}
                        className='rds-m_top__sm'
                    >
                        <Icon size={25} type='Setting' spin='true' />
                    </Card>
                </div>
            }
            example={`
<Icon
    type='People'
    theme={'outline' | 'filled' | 'two-tone' | 'multi-color'}
    variant={ThemeVariants.<VARIANT>}
/>`}
            properties={[
                {
                    name: 'type',
                    type: 'STRING',
                    description: "IconPark's icon name",
                    required: true,
                },
                {
                    name: 'size',
                    type: 'NUMBER',
                    description: 'Icon square size in pixels',
                },
                {
                    name: 'variant',
                    type: 'ThemeVariants',
                    description: "import { ThemeVariants } from 'rds'",
                },
                {
                    name: 'theme',
                    type: 'STRING',
                    description:
                        "'outline', 'filled', 'two-tone' or 'multi-color'",
                },
                {
                    name: 'spin',
                    type: 'STRING',
                    description:
                        'Whether spin animation should be applied (true) or not (false)',
                },
                {
                    name: 'strokeWidth',
                    type: 'NUMBER',
                    description: 'Stroke width in pixels, default is 4',
                },
            ]}
        />
    )
}

export default IconTemplate
